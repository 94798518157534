import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_order_payment_transaction_list(
    $filter: order_payment_transaction_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_order_payment_transaction_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        order {
          id
          reference
          customer {
            name
            mobile
            email
          }
        }
        invoice_number
        response_date_time
        payment_txn_id
        updated_date_time
        initiated_date_time
        currency
        status
        amount
        id
      }
      pagination {
        total_count
        page_limit
        page_number
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.OrderPaymentTransactionListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_order_payment_transaction_list_request = createAsyncThunk<
  TYPES.OrderPaymentTransactionList,
  {
    filter?: TYPES.OrderPaymentTransactionFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'order_payment_transaction/get_order_payment_transaction_list',
  async (
    params: {
      filter?: TYPES.OrderPaymentTransactionFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_order_payment_transaction_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_order_payment_transaction_list_slice = createSlice({
  name: 'get_order_payment_transaction_list',
  initialState: initial_state,
  reducers: {
    get_order_payment_transaction_list_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_order_payment_transaction_list_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_order_payment_transaction_list_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        get_order_payment_transaction_list_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: 'Failed to fetch',
            status_code: '500',
          };
          state.loading = false;
        },
      );
  },
});

export const { get_order_payment_transaction_list_clear } =
  get_order_payment_transaction_list_slice.actions;

export const get_order_payment_transaction_list = createSelector(
  (state: RootState) =>
    state.order_payment_transaction.get_order_payment_transaction_list.result,
  (state: RootState) =>
    state.order_payment_transaction.get_order_payment_transaction_list.error,
  (state: RootState) =>
    state.order_payment_transaction.get_order_payment_transaction_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_order_payment_transaction_list_slice.reducer;
