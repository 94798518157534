import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

// Define the prop types
interface RoleItemProps {
  item: {
    name?: string;
    [key: string]: any;
  };
  index: number;
}

const RoleItem: React.FC<RoleItemProps> = ({ item, index }) => {
  const dispatch = useDispatch();
  const [on_hover, setOnHover] = useState<boolean>(false);

  return (
    <div
      key={index}
      className="VStack"
      // style={{ minWidth: 200, maxWidth: 600 }}
    >
      <>{item.name}</>
    </div>
  );
};

export default RoleItem;
