import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageName from "./page_name";
import toast from "react-hot-toast";
import { get_uuid } from "../../../../helpers/functions";
import {
  AppDispatch,
  create_system_role,
  create_system_role_clear,
  create_system_role_request,
  create_system_ui_module_component,
  create_system_ui_module_component_clear,
  get_system_role_list_request,
  get_system_ui_module_list_request,
  update_system_role,
  update_system_role_clear,
  update_system_role_request,
  update_system_ui_module_component,
  update_system_ui_module_component_clear,
} from "../../../../redux";

interface RoleFormProps {
  close: () => void;
  action_type: string;
  get_id?: string;
  action_item?: {
    name?: string;
  };
  set_loading: (loading: boolean) => void;
  set_expanded_row_key: (key: string | null) => void;
  link_type?: string;
}

const RoleForm: React.FC<RoleFormProps> = (props) => {
  const {
    close,
    action_type,
    get_id,
    action_item,
    set_loading,
    set_expanded_row_key,
    link_type,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();

  const {
    result: create_status,
    loading: create_loading,
    error: create_error,
  } = useSelector(create_system_role);

  const {
    result: update_status,
    loading: update_loading,
    error: update_error,
  } = useSelector(update_system_role);

  useEffect(() => {
    set_loading(create_loading || update_loading);
  }, [create_loading, update_loading, set_loading]);

  useEffect(() => {
    if (create_status?.status === "success") {
      toast.success("Role created successfully");
      system_ui_module_list();
      close();
      dispatch(create_system_role_clear());
      dispatch(get_system_role_list_request({}));
    } else if (create_error?.message) {
      toast.error(create_error.message);
      system_ui_module_list();
      dispatch(create_system_role_clear());
    } else if (update_status?.status === "success") {
      toast.success("Role updated successfully");
      system_ui_module_list();
      close();
      dispatch(update_system_role_clear());
      dispatch(get_system_role_list_request({}));
    } else if (update_error?.message === "error") {
      toast.error(update_error.message);
      system_ui_module_list();
      dispatch(update_system_role_clear());
    }
  }, [
    create_status,
    create_error,
    update_status,
    update_error,
    close,
    dispatch,
    set_expanded_row_key,
    set_loading,
  ]);

  const system_ui_module_list = () => {
    let variable = {};
    dispatch(get_system_ui_module_list_request(variable));
  };

  useEffect(() => {
    system_ui_module_list();
  }, [dispatch]);

  const create_page = (data: any) => {
    let variable = { data };
    dispatch(create_system_role_request(variable));
  };

  const update_page = (data: any) => {
    let variable = { data, id: get_id || "" };
    dispatch(update_system_role_request(variable));
  };

  const on_finish = (values: any) => {
    let uuid = get_uuid();
    if (action_type === "add_role") {
      create_page({ ...values, id: uuid });
    } else {
      update_page({
        ...values,
        id: get_id,
      });
    }
  };

  useEffect(() => {
    if (action_type === "add_page") {
      form?.resetFields();
      form.setFieldsValue({});
    } else {
      form.setFieldsValue({ name: action_item?.name });
    }
  }, [action_item, action_type, form]);

  return (
    <div>
      <Form name="create page" onFinish={on_finish} id="page_form" form={form}>
        <PageName />
      </Form>
    </div>
  );
};

export default RoleForm;
