import { Form, Select } from "antd";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";

// Define the type for each API item
interface ApiItem {
  id?: string;
  name?: string;
}

// Define the props type
interface EndPointsProps {
  api_list?: any;
}

const EndPoints: React.FC<EndPointsProps> = ({ api_list }) => {
  const [api_items, set_api_items] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    if (api_list && api_list?.length > 0) {
      const temp = api_list?.map((x: any) => ({
        label: startCase(x.name),
        value: x.id,
      }));
      set_api_items(temp || []);
    }
  }, [api_list]);

  return (
    <>
      <Form.Item label="End Points" name={"api_module_id_list"}>
        <Select
          mode="multiple"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={api_items || []}
        />
      </Form.Item>
    </>
  );
};

export default EndPoints;
