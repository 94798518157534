import React from "react";
import { Spin } from "antd";
import {
  TbSquareRoundedCheck,
  TbSquareRoundedCheckFilled,
} from "react-icons/tb";

// Define the props type
interface CheckBoxComponentProps {
  loading: boolean;
  is_checked: boolean;
  onChange?: (checked: boolean) => void;
  key?: React.Key;
}

const CheckBoxComponent: React.FC<CheckBoxComponentProps> = (props) => {
  const { loading, is_checked, onChange, key } = props;

  const onClick = () => {
    if (!loading && onChange) {
      onChange(!is_checked);
    }
  };

  const CheckedStatus = () => {
    return (
      <>
        {!is_checked ? (
          <TbSquareRoundedCheck color="Black" size={20} />
        ) : (
          <TbSquareRoundedCheckFilled color="Black" size={20} />
        )}
      </>
    );
  };

  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        cursor: loading ? "default" : "pointer",
        borderRadius: loading ? "0" : "20px",
      }}
      onClick={onClick}
      key={key}
    >
      {loading && <Spin size="small" />}
      {!loading && <CheckedStatus />}
    </div>
  );
};

export default CheckBoxComponent;
