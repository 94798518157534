import axios from "axios";
import { get_uuid } from "./functions";
import { UploadFile } from "antd/es/upload/interface";
import { client } from "@src/redux";
import gql from "graphql-tag";
import { Upload } from "antd";
import toast from "react-hot-toast";

interface File {
  status?: "removed" | "uploading" | "done";
  url?: string;
  message?: string;
  type?: string;
  name?: string;
}
const file_query = gql`
  mutation get_signed_url($data: signed_url_input!) {
    get_signed_url(data: $data) {
      url
      filename
      status
      error {
        message
        status_code
      }
    }
  }
`;

let file_upload_response: any | null = null;
export let file_list: any = [];

export const action = file_upload_response?.data?.get_upload_url?.url || "";

export const handle_file_changed = ({ file }: { file: any }) => {
  if (file?.status === "removed") {
    file_list = [];
  } else if (file?.status === "uploading") {
    file = {
      ...file,
      url: file_upload_response?.data?.get_upload_url?.url,
      message: file_upload_response?.data?.get_upload_url?.message,
    };
    file_list = [file];
    return file;
  } else if (file?.status === "done") {
    file_list = [...file_list];
    return file;
  }
};

export const handle_before_upload = async (file: File) => {
  const { name, type } = file;
  const allowed_types = ["image/png", "image/jpg", "image/jpeg"];
  const valid_extensions = [".png", ".jpg", ".jpeg"];
  const is_valid_extensions = valid_extensions.some((ext) =>
    name?.endsWith(ext)
  );

  if (is_valid_extensions || allowed_types.includes(type || "")) {
    const file_upload = {
      type: "document",
      filename: `${get_uuid()}.${type?.split("/")?.[1]}`,
    };

    file_upload_response = await client.mutate({
      mutation: file_query,
      variables: { data: file_upload },
    });
  } else {
    file_upload_response = {
      data: {
        get_upload_url: {
          url: get_uuid(),
          message: "Allow only PNG, JPG and JPEG images",
        },
      },
    };
    if (file_upload_response?.data?.get_upload_url?.message) {
      toast.error(file_upload_response.data.get_upload_url.message);
      return Upload.LIST_IGNORE; // Prevent file from being uploaded
    }
  }
};

export const handle_upload = ({ onSuccess, onError, file }: any) => {
  if (file_upload_response?.data?.get_signed_url?.url) {
    axios
      .put(file_upload_response?.data?.get_signed_url?.url, file as any, {
        headers: { "Content-Type": file.type },
      })
      .then((response: any) => {
        file.url =
          file_upload_response?.data?.get_signed_url?.url.split("?")[0];
        onSuccess?.(null, file);
      })
      .catch((error: any) => {
        onError?.(error, file);
      });
  } else {
    onError?.(null, file_list?.[0]?.message);
  }
};
