import { useCheckPageAccess } from "@src/hooks";
import { useTranslation } from "react-i18next";
import { BiCategory, BiSolidCategory } from "react-icons/bi";
import { CgCalendarDates } from "react-icons/cg";
import {
  FaBitcoin,
  FaBoxes,
  FaBoxOpen,
  FaRegUser,
  FaShoppingCart,
} from "react-icons/fa";
import { BsCart4 } from "react-icons/bs";
import { GoPerson } from "react-icons/go";
import { GrCluster } from "react-icons/gr";
import { IoIosPeople } from "react-icons/io";
import { IoLocationSharp, IoSettingsSharp } from "react-icons/io5";
import { LuPackageCheck, LuTruck } from "react-icons/lu";
import {
  MdAssignmentReturn,
  MdDateRange,
  MdErrorOutline,
  MdOutlineGrading,
} from "react-icons/md";
import { PiMapPinAreaFill, PiSealPercentFill } from "react-icons/pi";
import { RiTeamFill } from "react-icons/ri";
import { TiShoppingCart } from "react-icons/ti";
import { TbTransactionRupee } from "react-icons/tb";

interface MenuItem {
  name: string;
  pathname?: string;
  route?: string;
  Icon: any;
  key?: string;
  keys?: string[];
  children?: MenuItem[]; // Add this for nested items
}
export const menuItems: MenuItem[] = [
  {
    name: "customer_orders",
    pathname: "/admin/customer-orders",
    route: "customer-orders",
    Icon: FaShoppingCart,
    key: "Customer Orders",
  },
  {
    name: "stock_management",
    pathname: "/admin/stock-management",
    route: "stock-management",
    Icon: FaBoxes,
    key: "Stock Management",
  },
  {
    name: "return_orders",
    pathname: "/admin/return-orders",
    route: "return-orders",
    Icon: MdAssignmentReturn,
    key: "Return Order",
  },
  {
    name: "customer_credits",
    pathname: "/admin/customer-credits",
    route: "customer-credits",
    Icon: FaBitcoin,
    key: "Customer Credits",
  },
  {
    name: "Customers",
    pathname: "/admin/customers",
    route: "customers",
    Icon: IoIosPeople,
    key: "Customers",
  },

  {
    name: "payment_transaction",
    pathname: "/admin/payment-transaction",
    route: "payment-transaction",
    Icon: TbTransactionRupee,
    key: "Payment Transaction",
  },

  {
    name: "products",
    pathname: "/admin/products",
    route: "products",
    Icon: FaBoxOpen,
    key: "Products",
  },
  {
    name: "category",
    pathname: "/admin/categories",
    route: "categories",
    Icon: BiSolidCategory,
    key: "Categories",
  },

  {
    name: "product_gst",
    pathname: "/admin/product-gst",
    route: "product-gst",
    Icon: PiSealPercentFill,
    key: "Product GST",
  },
];

export const filterMenu = (menu: MenuItem[]) => {
  return menu.filter((item: MenuItem) => {
    if (item?.children) {
      item.children = filterMenu(item?.children);
    }
    if (item?.key) {
      return useCheckPageAccess(item?.key);
    } else if (item?.keys) {
      return item.keys.some((key) => useCheckPageAccess(key));
    }
    return false;
  });
};
