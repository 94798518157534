import React from "react";
import { Spin } from "antd";
import {
  TbSquareRoundedCheck,
  TbSquareRoundedCheckFilled,
} from "react-icons/tb";

interface UiCheckBoxComponentProps {
  loading: boolean;
  is_checked: boolean;
  onChange?: (checked: boolean) => void;
  key?: any;
  index?: any;
}

const UiCheckBoxComponent: React.FC<UiCheckBoxComponentProps> = ({
  loading,
  is_checked,
  onChange,
  key,
}) => {
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!loading && onChange) {
      onChange(!is_checked);
    }
  };

  const CheckedStatus = () => (
    <>
      {!is_checked ? (
        <TbSquareRoundedCheck color="Black" size={20} />
      ) : (
        <TbSquareRoundedCheckFilled color="Black" size={20} />
      )}
    </>
  );

  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        cursor: loading ? "default" : "pointer",
        borderRadius: loading ? "0" : "20px",
      }}
      onClick={onClick}
      // key={key}
    >
      {loading ? <Spin size="small" /> : <CheckedStatus />}
    </div>
  );
};

export default UiCheckBoxComponent;
