import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  mutation delete_product_stock_list($filter: product_stock_filter_input!) {
    delete_product_stock_list(filter: $filter) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const delete_product_stock_list_request = createAsyncThunk<
  TYPES.MutationResponse,
  { filter: TYPES.ProductStockFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'product_stock/delete_product_stock_list',
  async (
    params: { filter: TYPES.ProductStockFilterInput },
    { extra: client },
  ) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.delete_product_stock_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const delete_product_stock_list_slice = createSlice({
  name: 'delete_product_stock_list',
  initialState: initial_state,
  reducers: {
    delete_product_stock_list_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(delete_product_stock_list_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(delete_product_stock_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(delete_product_stock_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { delete_product_stock_list_clear } =
  delete_product_stock_list_slice.actions;

export const delete_product_stock_list = createSelector(
  (state: RootState) => state.product_stock.delete_product_stock_list.result,
  (state: RootState) => state.product_stock.delete_product_stock_list.error,
  (state: RootState) => state.product_stock.delete_product_stock_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default delete_product_stock_list_slice.reducer;
