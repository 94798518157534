import { combineReducers } from '@reduxjs/toolkit';
import get_product_stock_log_reducer from './get_product_stock_log';
import get_product_stock_log_list_reducer from './get_product_stock_log_list';

const product_stock_log_reducer = combineReducers({
  get_product_stock_log: get_product_stock_log_reducer,
  get_product_stock_log_list: get_product_stock_log_list_reducer,
});

export default product_stock_log_reducer;
