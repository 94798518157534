import { combineReducers } from '@reduxjs/toolkit';
import create_customer_reducer from './create_customer';
import delete_customer_reducer from './delete_customer';
import get_customer_reducer from './get_customer';
import get_customer_list_reducer from './get_customer_list';
import update_customer_reducer from './update_customer';

const customer_reducer = combineReducers({
  create_customer: create_customer_reducer,
  delete_customer: delete_customer_reducer,
  get_customer: get_customer_reducer,
  get_customer_list: get_customer_list_reducer,
  update_customer: update_customer_reducer,
});

export default customer_reducer;
