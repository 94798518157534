import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { formatStatus } from "@src/helpers/status_constants";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  get_customer_list,
  get_customer_list_request,
  get_order_list,
  get_order_list_request,
} from "@src/redux";
import DynamicTable, {
  DynamicTableColumn,
  DynamicTableHandle,
} from "../general/dynamic_table";
import moment from "moment";
import { Typography } from "antd";
import { DynamicFilterField } from "../general/dynamic_form";

const { Title, Text } = Typography;

interface OrderData {
  key: string;
  orderId: string;
  customerName: string;
  totalAmount: string;
  mobile: Number;
  status: String;
}

interface LocationOption {
  label: string;
  value: string;
}

const CustomerOrderTab: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: customer_id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const table_ref = useRef<DynamicTableHandle>(null);
  const [is_modal_open, set_is_modal_open] = useState(false);

  const columns: DynamicTableColumn[] = [
    {
      title: t("table:order_id"),
      sorter: true,

      render: (record: any) => (
        <Link to={`/admin/customer-order/${record?.id}`}>
          {record?.reference}
        </Link>
      ),
    },
    {
      title: t("table:ordered_datetime"),
      data_index: "ordered_datetime",
      sorter: true,
      render: (record: Date) => moment(record).format("DD/MM/YYYY hh:mm a"),
    },
    {
      title: t("table:name"),
      data_index: "customer.name",
      sorter: true,
    },
    {
      title: t("table:outlet"),
      data_index: "franchise.name",
      sorter: true,
    },

    {
      title: t("table:total_products"),
      data_index: "line_item_list",
      sorter: true,
      fixed: "left",
      render: (total_products: any) => (
        <Text>{total_products?.length || 0}</Text>
      ),
    },
    {
      title: t("table:payment_status"),
      data_index: "payment_status",
      sorter: true,
      render: formatStatus,
    },
    {
      title: t("table:total_amount"),
      data_index: "total_amount",
      sorter: true,
      render: (total_amount: any) => (
        <Text>{total_amount.toFixed(2) || 0}</Text>
      ),
    },

    {
      title: t("table:status"),
      data_index: "status",
      sorter: true,
      render: formatStatus,
    },
    // {
    //   title: "Actions",
    //   fixed: "right",
    //   actions: [
    //     {
    //       type: "view",
    //       icon: <FaEye />,
    //     },
    //   ],
    // },
  ];

  const Status_options: LocationOption[] = [
    {
      label: "Placed",
      value: "placed",
    },
    {
      label: "In Progress",
      value: "in_progress",
    },
    {
      label: "Delivered",
      value: "delivered",
    },
  ];

  const filters: DynamicFilterField[] = [
    // {
    //   name: "customer_id",
    //   label: "Customer",
    //   type: "select",
    //   source: {
    //     request: get_customer_list_request,
    //     selector: get_customer_list,
    //   },
    //   value_field: "customer_id",
    //   class_name: "search-box",
    //   layout: { span: 4, xs: 8, xl: 6, xxl: 6 },
    //   label_col: { xs: 10, sm: 10 },
    //   wrapper_col: { xs: 14, sm: 14 },
    // },
    {
      name: "status",
      label: "Status",
      type: "select",
      class_name: "search-box",
      options: Status_options,
      layout: { span: 4, xs: 8, xl: 8, xxl: 8 },
      label_col: { xs: 10, sm: 10 },
      wrapper_col: { xs: 14, sm: 14 },
    },
    // {
    //   name: "reference",
    //   // label: "Search",
    //   type: "search",
    //   class_name: "search-box",
    //   layout: { span: 4, xs: 8, xl: 4, xxl: 4 },
    //   label_col: { xs: 24, sm: 24 },
    //   wrapper_col: { xs: 24, sm: 24 },
    // },
  ];

  function on_action(type: any, record?: any) {
    if (type === "view") {
      navigate(`/admin/customer-order/${record.id}`);
    } else {
      set_is_modal_open(true);
    }
  }

  return (
    <div style={{ padding: "24px" }}>
      <DynamicTable
        ref={table_ref}
        columns={columns}
        filters={filters}
        source={{
          request: get_order_list_request,
          selector: get_order_list,
          params: {
            filter: {
              customer_id: customer_id,
            },
          },
        }}
        pagination={{
          page_size: 10,
          show_total: "Total {total} Orders",
        }}
        on_action={on_action}
      />
    </div>
  );
};

export default CustomerOrderTab;
