import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Dropdown, Layout } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IoMdHome } from "react-icons/io";
import { HEADER_LOGO, PROFILE } from "@src/helpers/images_constants";
import { retrieve_item } from "@src/helpers/functions";
import { useDynamicSelector } from "@src/redux";

const { Header } = Layout;

interface HeaderBarProps {
  title: string;
  show_title: boolean;
  set_title: (title: string) => void;
  toggle: () => void;
  collapsed: boolean;
}

const HeaderBar: React.FC<HeaderBarProps> = memo((props) => {
  const { title, show_title, set_title, toggle, collapsed } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const user_name = retrieve_item("user_name");
  const brand = retrieve_item("brand");

  useEffect(() => {
    let parts = document.title.split("|");
    let place = parts[0].trim();
    set_title(place);
  }, [set_title]);

  const items = [
    {
      label: t("logout"),
      key: "1",
    },
  ];

  const handleMenuClick = (e: { key: string }) => {
    if (e.key === "1") {
      localStorage.clear();
      navigate("/admin");
    }
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  return (
    <Header className={brand === "teaboy" ? "header" : "header_bp"}>
      {/* <IoMdHome size={35} color="#e33969" /> */}
      {/* <div className="header_logo">
        <img src={HEADER_LOGO} className="teaboy_logo" alt="TeaBoy Logo" />
      </div> */}
      <Dropdown
        menu={{
          items,
          onClick: handleMenuClick,
        }}
        onOpenChange={handleOpenChange}
        open={open}
      >
        <div className="header-user-container">
          <span className="user-name-header">{user_name}</span>
          <Avatar
            icon={<UserOutlined />}
            src={PROFILE}
            alt="Profile"
            className="profile_logo"
          />
        </div>
      </Dropdown>
    </Header>
  );
});

export default HeaderBar;
