import { createSelector, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "@src/redux/store";
import { useDispatch, useSelector } from "react-redux";

const initial_state: any = {
  data: {},
  loading: false,
};

const slice = createSlice({
  name: "dynamic",
  initialState: initial_state,
  reducers: {
    _set_state: (state, { payload: { key, value } }) => {
      state.data[key] = value;
    },
    _clear_state: (state, { payload: { key } }) => {
      state.data[key] = null;
    },
    _remove_state: (state, { payload: { key } }) => {
      let data = state.data;
      data = JSON.parse(JSON.stringify(data));
      delete data[key];
      state.data = data;
    },
  },
});

const { _set_state, _clear_state, _remove_state } = slice.actions;

export const dynamic_selector = (state: any) => state.dynamic;

export const dynamic_reducer = slice.reducer;

const _getDynamicRequest = (state: any) => state.dynamic?.data;

export const _dynamicRequestSelector = createSelector(
  [_getDynamicRequest, (state, key) => key],
  (data, key) => data?.[key]
);

export function useReduxDispatch() {
  const dispatch = useDispatch<AppDispatch>();
  return dispatch;
}

export function useDynamicSelector(key: string) {
  const _data = useSelector((state) => _dynamicRequestSelector(state, key));
  return _data || {};
}

export function dynamic_set(key: string, value: any) {
  return async (dispatch: any) => {
    dispatch(_set_state({ key, value }));
  };
}

export function dynamic_clear(key: string) {
  return async (dispatch: any) => {
    dispatch(_clear_state({ key }));
  };
}

export function dynamic_remove(key: string) {
  return async (dispatch: any) => {
    dispatch(_remove_state({ key }));
  };
}
