import { combineReducers } from '@reduxjs/toolkit';
import create_order_line_item_reducer from './create_order_line_item';
import delete_order_line_item_reducer from './delete_order_line_item';
import get_order_line_item_reducer from './get_order_line_item';
import get_order_line_item_list_reducer from './get_order_line_item_list';
import update_order_line_item_reducer from './update_order_line_item';

const order_line_item_reducer = combineReducers({
  create_order_line_item: create_order_line_item_reducer,
  delete_order_line_item: delete_order_line_item_reducer,
  get_order_line_item: get_order_line_item_reducer,
  get_order_line_item_list: get_order_line_item_list_reducer,
  update_order_line_item: update_order_line_item_reducer,
});

export default order_line_item_reducer;
