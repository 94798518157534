import { combineReducers } from '@reduxjs/toolkit';
import create_franchise_reducer from './create_franchise';
import delete_franchise_reducer from './delete_franchise';
import get_franchise_reducer from './get_franchise';
import get_franchise_list_reducer from './get_franchise_list';
import update_franchise_reducer from './update_franchise';

const franchise_reducer = combineReducers({
  create_franchise: create_franchise_reducer,
  delete_franchise: delete_franchise_reducer,
  get_franchise: get_franchise_reducer,
  get_franchise_list: get_franchise_list_reducer,
  update_franchise: update_franchise_reducer,
});

export default franchise_reducer;
