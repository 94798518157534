import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageDeleteModal from "./page_delete_modal";
import UIModulePages from "./pages/page";
import RoleItem from "./pages/page_role_item";
import PageComponentRoleCheckBox from "./pages/page_component_role_check_box";
import UIModulePage from "./pages/module_page";
import PageUiComponentRoleCheckBox from "./pages/ui_component_role_check_box";
import toast from "react-hot-toast";
import {
  AppDispatch,
  delete_system_ui_module,
  delete_system_ui_module_clear,
  delete_system_ui_module_component,
  delete_system_ui_module_component_clear,
  delete_system_ui_module_component_request,
  delete_system_ui_module_request,
  get_system_role_list,
  get_system_role_list_request,
  get_system_ui_module_list,
  get_system_ui_module_list_request,
} from "../../../../redux";

// Define the types for the props
interface PageListProps {
  set_open_modal: (open: boolean) => void;
  set_action_type: (type: string) => void;
  set_filed_id: (id: string) => void;
  set_action_item: (item: any) => void;
  get_id: string;
  set_link_open_modal: (open: boolean) => void;
  set_expanded_row_key: (key: string | null) => void;
  expanded_row_key: string | null;
  set_link_type: (type: string) => void;
}

// Define types for the record and data source
interface RecordType {
  id?: string;
  name?: string;
  type?: string;
  children?: RecordType[];
  ui_module_component_list?: RecordType[];
}

interface Role {
  id?: string;
  name?: string;
}

interface PageListState {
  isHovered: boolean;
  delete_modal_open: boolean;
  delete_name: string;
  delete_id: string;
  action_delete: string | null;
  page_items: RecordType[];
}

const PageList: React.FC<PageListProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const { result: system_role_list_items, loading: system_role_list_loading } =
    useSelector(get_system_role_list);
  const { result: page_list, loading: get_system_ui_module_list_loading } =
    useSelector(get_system_ui_module_list);

  const {
    result: delete_result,
    loading: delete_loading,
    error: delete_error,
  } = useSelector(delete_system_ui_module);

  const {
    result: delete_page_result,
    loading: delete_page_loading,
    error: delete_page_error,
  } = useSelector(delete_system_ui_module_component);

  const {
    set_open_modal,
    set_action_type,
    set_filed_id,
    set_action_item,
    get_id,
    set_link_open_modal,
    set_expanded_row_key,
    expanded_row_key,
    set_link_type,
  } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [delete_modal_open, set_delete_modal_open] = useState<boolean>(false);
  const [delete_name, set_delete_name] = useState<string>("");
  const [delete_id, set_delete_id] = useState<string>("");
  const [action_delete, set_action_delete] = useState<string | null>(null);
  const [page_items, set_page_items] = useState<RecordType[]>([]);

  const handle_add = (record: RecordType) => {
    set_open_modal(true);
    set_action_type("add_ui_component");
    set_filed_id(record?.id || "");
    set_action_item({});
  };

  const handle_link = (record: RecordType) => {
    set_link_open_modal(true);
    set_link_type("link_component");
    set_action_type("");
    set_filed_id(record?.id || "");
    set_action_item(record);
  };

  const handle_edit = (record: RecordType) => {
    set_open_modal(true);
    set_action_type("edit_page");
    set_filed_id(record?.id || "");
    set_action_item(record);
  };

  const handle_edit_component = (record: RecordType) => {
    set_open_modal(true);
    set_action_type("edit_page_component");
    set_filed_id(record?.id || "");
    set_link_type("");
    set_action_item(record);
  };

  const handle_delete = (record: RecordType) => {
    const delete_id = record?.id;
    set_delete_modal_open(true);
    set_action_delete("delete_page");
    set_delete_name(record?.name || "");
    set_delete_id(delete_id || "");
  };

  const handle_delete_component = (record: RecordType) => {
    const delete_id = record?.id;
    set_delete_modal_open(true);
    set_action_delete("delete_page_component");
    set_delete_name(record?.name || "");
    set_delete_id(delete_id || "");
  };

  const handle_delete_close = () => {
    set_delete_modal_open(false);
  };

  const delete_one_system_ui_module = (id: string) => {
    const variable = {
      id,
      data: { id: delete_name, name: delete_name },
    };
    dispatch(delete_system_ui_module_request(variable));
  };

  const delete_one_system_ui_module_component = (id: string) => {
    const variable = {
      id,
      data: {
        id: delete_name,
        name: delete_name,
        ui_module_id: get_id,
      },
    };
    dispatch(delete_system_ui_module_component_request(variable));
  };

  useEffect(() => {
    if (delete_result?.status === "success") {
      toast.success("Page deleted successfully");
      system_ui_module_list();
      handle_delete_close();
      dispatch(delete_system_ui_module_clear());
    } else if (delete_error?.message === "error") {
      toast.error(delete_error.message);
      system_ui_module_list();
      dispatch(delete_system_ui_module_clear());
    } else if (delete_page_result?.status === "success") {
      toast.success("Page component deleted successfully");
      system_ui_module_list();
      handle_delete_close();
      set_expanded_row_key(null);
      dispatch(delete_system_ui_module_component_clear());
    } else if (delete_page_error?.message === "error") {
      toast.error(delete_page_error.message);
      system_ui_module_list();
      set_expanded_row_key(null);
      dispatch(delete_system_ui_module_component_clear());
    }
  }, [delete_result, delete_error, delete_page_result, delete_page_error]);

  const on_delete = () => {
    if (action_delete === "delete_page") {
      delete_one_system_ui_module(delete_id);
    } else {
      delete_one_system_ui_module_component(delete_id);
    }
  };

  const system_role_list = () => {
    const variable = {};
    dispatch(get_system_role_list_request(variable));
  };

  const system_ui_module_list = () => {
    const variable = {};
    dispatch(get_system_ui_module_list_request(variable));
  };

  useEffect(() => {
    system_role_list();
    system_ui_module_list();
  }, []);

  useEffect(() => {
    if (page_list?.items) {
      set_page_items(page_list?.items || []);
    }
  }, [page_list]);

  const handleExpand = (record: RecordType) => {
    if (expanded_row_key === record?.id) {
      set_expanded_row_key(null);
    } else {
      set_expanded_row_key(record?.id || "");
    }
  };

  const columns: any[] = [
    {
      title: "",
      dataIndex: "",
      key: "",
      fixed: "left",
      width: "2%",
    },
    {
      title: "Pages",
      dataIndex: "",
      key: "",
      fixed: "left",
      width: "15%",
      render: (page: any, record: RecordType, page_index: number) => {
        if (record?.type === "page") {
          return (
            <UIModulePages
              key={page_index}
              handle_add={handle_add}
              handle_edit={handle_edit}
              handle_delete={handle_delete}
              setIsHovered={setIsHovered}
              expandedRowKey={expanded_row_key}
              record={page}
            />
          );
        } else {
          return (
            <UIModulePage
              key={page_index}
              handle_link={handle_link}
              handle_edit={handle_edit_component}
              handle_delete={handle_delete_component}
              setIsHovered={setIsHovered}
              isHovered={isHovered}
              expandedRowKey={expanded_row_key || ""}
              record={page}
            />
          );
        }
      },
    },
    ...(system_role_list_items?.items?.map(
      (role: Role, role_index: number) => ({
        title: <RoleItem key={role_index} item={role} index={0} />,
        dataIndex: "customDataIndex",
        key: `role_${role_index}`,
        width: "150px",
        render: (text: any, record: RecordType, index: number) => {
          if (record?.type === "page") {
            return (
              <PageComponentRoleCheckBox
                page_component={record}
                role={role || ""}
                key={index}
              />
            );
          } else {
            return (
              <PageUiComponentRoleCheckBox
                page_component={record}
                role={role}
                index={index}
                key={index}
              />
            );
          }
        },
      })
    ) || []),
  ];

  const row_class_name = (
    record: RecordType,
    index: number,
    categories_list: RecordType[]
  ) => {
    if (record?.children) {
      const my_category_index = categories_list?.findIndex(
        (x) => x.id === record?.id
      );

      if (my_category_index % 2 === 0) {
        return "page-even-row";
      }
      return "page-odd-row";
    } else {
      const my_category = categories_list?.find((x) => x?.id === record?.id);
      const my_category_products = my_category?.ui_module_component_list;
      const my_index =
        my_category_products?.findIndex((x) => x?.id === record?.id) || 0;
      const is_my_index_even = my_index % 2 === 0;

      return is_my_index_even ? "page-access-even-row" : "page-access-odd-row";
    }
  };

  return (
    <div
      className="custom-table"
      style={{
        width: "100%",
        overflowX: "auto",
      }}
    >
      <Table
        loading={system_role_list_loading}
        sticky={{
          offsetHeader: 0,
        }}
        dataSource={page_items?.map((item, index) => ({
          ...item,
          key: index,
          type: "page",
          children: item?.ui_module_component_list,
        }))}
        onExpand={(expanded, record) => {
          handleExpand(record);
        }}
        // expandedRowKeys={expanded_row_key !== null ? [expanded_row_key] : []}
        pagination={false}
        className="table-striped-rows admin-table"
        scroll={{ x: "max-content" }}
        columns={columns}
        rowClassName={(record, index) =>
          row_class_name(record, index, page_items)
        }
      />
      <PageDeleteModal
        delete_modal_open={delete_modal_open}
        handle_delete_close={handle_delete_close}
        delete_name={delete_name}
        on_delete={on_delete}
        delete_loading={delete_loading}
        delete_page_loading={delete_page_loading}
        action_delete={action_delete || ""}
      />
    </div>
  );
};

export default PageList;
