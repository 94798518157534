import { Form, Input } from "antd";
import React from "react";

// Define the functional component as React.FC
const PageName: React.FC = () => {
  return (
    <>
      <Form.Item
        name="name" // Changed from string literal to string
        label="Name" // Changed from string literal to string
        rules={[
          {
            required: true,
            message: "Please enter name",
          },
        ]}
      >
        <Input placeholder="Name" type="text" />
      </Form.Item>
    </>
  );
};

export default PageName;
