import { combineReducers } from '@reduxjs/toolkit';
import create_product_stock_reducer from './create_product_stock';
import create_product_stock_list_reducer from './create_product_stock_list';
import delete_product_stock_reducer from './delete_product_stock';
import delete_product_stock_list_reducer from './delete_product_stock_list';
import get_product_stock_reducer from './get_product_stock';
import get_product_stock_list_reducer from './get_product_stock_list';
import update_product_stock_reducer from './update_product_stock';
import update_product_stock_list_reducer from './update_product_stock_list';

const product_stock_reducer = combineReducers({
  create_product_stock: create_product_stock_reducer,
  create_product_stock_list: create_product_stock_list_reducer,
  delete_product_stock: delete_product_stock_reducer,
  delete_product_stock_list: delete_product_stock_list_reducer,
  get_product_stock: get_product_stock_reducer,
  get_product_stock_list: get_product_stock_list_reducer,
  update_product_stock: update_product_stock_reducer,
  update_product_stock_list: update_product_stock_list_reducer,
});

export default product_stock_reducer;
