import { combineReducers } from '@reduxjs/toolkit';
import get_state_reducer from './get_state';
import get_state_list_reducer from './get_state_list';

const state_reducer = combineReducers({
  get_state: get_state_reducer,
  get_state_list: get_state_list_reducer,
});

export default state_reducer;
