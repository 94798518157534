import { combineReducers } from '@reduxjs/toolkit';
import create_customer_credit_reducer from './create_customer_credit';
import delete_customer_credit_reducer from './delete_customer_credit';
import get_customer_credit_reducer from './get_customer_credit';
import get_customer_credit_list_reducer from './get_customer_credit_list';
import update_customer_credit_reducer from './update_customer_credit';

const customer_credit_reducer = combineReducers({
  create_customer_credit: create_customer_credit_reducer,
  delete_customer_credit: delete_customer_credit_reducer,
  get_customer_credit: get_customer_credit_reducer,
  get_customer_credit_list: get_customer_credit_list_reducer,
  update_customer_credit: update_customer_credit_reducer,
});

export default customer_credit_reducer;
