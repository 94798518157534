import React from "react";
import lodash from "lodash";

import { useCheckLogin, useCheckPageAccess } from "@src/hooks";
import { BLACK_PEKOE, TEABOY } from "@src/helpers/constants";
import { Navigate } from "react-router-dom";
import { useDynamicSelector } from "@src/redux";
import { retrieve_item } from "@src/helpers/functions";

export default function MyRoute({
  name,
  authenticate,
  children,
}: {
  name: string;
  authenticate: boolean;
  children: React.ReactNode;
}) {
  const brand = retrieve_item("brand");
  if (name) {
    let page_name = lodash.startCase(lodash.camelCase(name));
    document.title =
      page_name + " | " + `${brand === "teaboy" ? TEABOY : BLACK_PEKOE}`;
  } else document.title = `${brand === "teaboy" ? TEABOY : BLACK_PEKOE}`;

  const isAuthenticated = useCheckLogin();
  const is_page_access = useCheckPageAccess(name);

  if (authenticate) {
    if (isAuthenticated && is_page_access) {
      return children;
    } else {
      return <Navigate to="/not-allowed" />;
    }
  } else {
    return children;
  }
}
