import {
  AppDispatch,
  create_order_line_item,
  create_order_line_item_clear,
  create_order_line_item_request,
  get_order_line_item_list,
  get_order_line_item_list_request,
  get_order_request,
  get_product_list,
  get_product_list_request,
  Product,
  update_order_line_item,
  update_order_line_item_clear,
  update_order_line_item_request,
} from "@src/redux";
import { Button, Col, Form, InputNumber, Row, Select } from "antd";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

type Props = { form: any; line_item?: any; onClose: Function };

const OrderLineItemForm = (props: Props) => {
  const { form, onClose, line_item } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const { result: product_list } = useSelector(get_product_list);
  const {
    result: create_order_line_item_status,
    error: create_order_line_item_error,
    loading: create_order_line_item_loading,
  } = useSelector(create_order_line_item);
  const {
    result: update_order_line_item_status,
    error: update_order_line_item_error,
    loading: update_order_line_item_loading,
  } = useSelector(update_order_line_item);
  const on_finish = (values: any) => {
    let find_product = product_list?.items?.find((list: Product) =>
      list.id?.includes(values?.product_id)
    );
    if (!line_item?.id) {
      dispatch(
        create_order_line_item_request({
          data: {
            ...values,
            order_id: id,
            product_id: find_product?.id,
            quantity: values?.quantity,
            price: find_product?.price,
            gst: find_product?.gst?.percentage,
          },
        })
      );
    } else {
      dispatch(
        update_order_line_item_request({
          id: line_item?.id,
          data: {
            order_id: id,
            product_id: find_product?.id,
            quantity: values?.quantity,
            price: find_product?.price,
            gst: find_product?.gst?.percentage,
          },
        })
      );
    }
  };
  useEffect(() => {
    dispatch(get_product_list_request({}));
  }, [dispatch]);
  console.log(create_order_line_item_status, "create_order_line_item_error");

  useEffect(() => {
    if (create_order_line_item_status?.status === "success") {
      toast.success(t("product_added_successfully"));
      dispatch(create_order_line_item_clear());
      onClose();
      dispatch(get_order_line_item_list_request({ filter: { order_id: id } }));
      dispatch(get_order_request({ filter: { id: id } }));
    } else if (update_order_line_item_status?.status === "success") {
      toast.success(t("product_update_successfully"));
      dispatch(update_order_line_item_clear());
      dispatch(get_order_line_item_list_request({ filter: { order_id: id } }));
      dispatch(get_order_request({ filter: { id: id } }));
      onClose();
    } else if (create_order_line_item_status?.error) {
      toast.error(t(create_order_line_item_status?.error?.message));
      dispatch(create_order_line_item_clear());
    } else if (
      update_order_line_item_error?.message ||
      create_order_line_item_error?.message
    ) {
      toast.error(t("something went wrong"));
      dispatch(update_order_line_item_clear());
    }
  }, [
    create_order_line_item_status,
    update_order_line_item_status,
    create_order_line_item_error,
    update_order_line_item_error,
  ]);
  return (
    <div>
      <Form form={form} onFinish={on_finish} layout="vertical">
        <Form.Item
          name={"product_id"}
          label={t("product")}
          rules={[{ required: true, message: t("error:product_is_required") }]}
        >
          <Select
            options={product_list?.items || []}
            fieldNames={{ label: "name", value: "id" }}
          />
        </Form.Item>
        <Form.Item
          name={"quantity"}
          label={t("quantity")}
          rules={[{ required: true, message: t("error:quantity_is_required") }]}
        >
          <InputNumber
            changeOnWheel={false}
            controls={false}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Row justify={"end"} gutter={10}>
          <Col>
            <Button danger onClick={() => onClose()}>
              {t("close")}
            </Button>
          </Col>
          <Col>
            <Button
              htmlType="submit"
              type="primary"
              loading={
                create_order_line_item_loading || update_order_line_item_loading
              }
            >
              {t("submit")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OrderLineItemForm;
