import { Typography } from "antd";
import React from "react";

interface PageDeleteProps {
  delete_name: any;
}

const PageDelete: React.FC<PageDeleteProps> = ({ delete_name }) => {
  const { Text } = Typography;

  return (
    <div>
      <Text>
        Do you want to delete the page{" "}
        <span style={{ fontWeight: "bold" }}>{delete_name.name}</span>?
      </Text>
    </div>
  );
};

export default PageDelete;
