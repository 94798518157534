import { Button } from "antd";
import React from "react";
import PageDelete from "./page_delete";
import CommonModal from "../../common/modal/custom_modal";

// Define the props type
interface PageDeleteModalProps {
  delete_modal_open: boolean;
  handle_delete_close: () => void;
  delete_loading: boolean;
  on_delete: () => void;
  delete_name: string;
  action_delete: string;
  delete_page_loading: boolean;
}

const PageDeleteModal: React.FC<PageDeleteModalProps> = (props) => {
  const {
    delete_modal_open,
    handle_delete_close,
    delete_loading,
    on_delete,
    delete_name,
    action_delete,
    delete_page_loading,
  } = props;

  return (
    <>
      <CommonModal
        width={500}
        open={delete_modal_open}
        onCancel={handle_delete_close}
        data-custom-delete-modal="custom-delete-modal-header"
        title={
          action_delete === "delete_page"
            ? "Delete Page"
            : "Delete Page Component"
        }
        footer={
          <>
            <Button onClick={handle_delete_close}>Cancel</Button>
            <Button
              htmlType="submit"
              onClick={on_delete}
              loading={delete_loading || delete_page_loading}
            >
              Delete
            </Button>
          </>
        }
      >
        <PageDelete delete_name={delete_name}  />
      </CommonModal>
    </>
  );
};

export default PageDeleteModal;
