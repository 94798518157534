import React, { memo, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import {
  Sidebar,
  Menu,
  MenuItem,
  menuClasses,
  SubMenu,
} from "react-pro-sidebar";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import {
  HEADER_LOGO,
  HEADER_LOGO_BP,
  HEADER_LOGO_WHITE,
  PROFILE_WITHOUT_TEXT,
  SIDEBAR,
} from "@src/helpers/images_constants";
import HeaderBar from "./admin_layout/header";
import { filterMenu, menuItems } from "./admin_layout/menu_list"; // Ensure menuItems is correctly imported here
import { retrieve_item } from "@src/helpers/functions";
import { useDynamicSelector } from "@src/redux";

const { Content } = Layout;

interface MenuItem {
  name: string;
  pathname?: string;
  route?: string;
  Icon: any;
  key?: string;
  keys?: string[];
  children?: MenuItem[]; // Add this for nested items
}

const AdminLayoutTemplate: React.FC = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const brand = retrieve_item("brand");

  const { t } = useTranslation();

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [showTitle, setShowTitle] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");

  const toggle = (): void => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const parts = document.title.split("|");
    const place = parts[0].trim();
    setTitle(place);
  }, []);

  const handleScroll = (value: { scrollTop: number }): void => {
    const scroll = value.scrollTop;
    setShowTitle(scroll > 40);
  };

  const handleNavigate = (path: string): void => {
    navigate(`/admin/${path}`);
  };

  const filterMenuList = filterMenu(menuItems);

  const renderMenuItems = (items: MenuItem[]) => {
    return items.map((item) => {
      if (item?.keys && item?.keys.length > 0) {
        const isActive = (item: MenuItem): boolean => {
          let check_key = item?.keys?.some((value) =>
            value?.includes(location?.pathname)
          );
          if (check_key) {
            return true;
          }

          return false;
        };
        let Icons = item.Icon;

        return (
          <SubMenu
            key={item.key}
            icon={<Icons size={25} />}
            defaultOpen={isActive(item)} // Automatically open if any child is active
            onClick={() => item?.route && handleNavigate(item?.route)}
            label={t(item.name)}
            className="admin-layout-submenu-item"
          >
            {renderMenuItems(item?.children || [])}
          </SubMenu>
        );
      } else {
        const isActive = (item: MenuItem): boolean => {
          if (location.pathname.includes(item?.pathname || "")) {
            return true;
          }
          if (item.children) {
            return item.children.some((child) =>
              location.pathname.includes(child?.pathname || "")
            );
          }
          return false;
        };
        let Icons = item.Icon;

        return (
          <MenuItem
            key={item.key}
            icon={
              <Icons size={25} color={isActive(item) ? "#ffa500" : "white"} />
            }
            active={isActive(item)} // Use the custom isActive function
            onClick={() => item?.route && handleNavigate(item?.route)}
          >
            {t(item.name)}
          </MenuItem>
        );
      }
    });
  };

  return (
    <div>
      <Layout className="layout">
        <Sidebar
          collapsed={collapsed}
          className="layout_sidebar"
          style={{
            width: collapsed ? 80 : 200,
            height: "100vh",
            position: "sticky",
            top: 0,
            zIndex: 1000,
            border: "none",
          }}
          image={SIDEBAR}
        >
          <div
            className={
              brand === "teaboy"
                ? "sidebar_header_image_container"
                : "sidebar_header_image_container_bp"
            }
          >
            <img
              className="sidebar_header_image"
              alt="Logo"
              src={
                collapsed
                  ? PROFILE_WITHOUT_TEXT
                  : brand === "teaboy"
                  ? HEADER_LOGO_WHITE
                  : HEADER_LOGO_BP
              }
            />
          </div>
          <Scrollbars id="sidebar" className="sidebar_content">
            <Menu
              rootStyles={{
                ["." + menuClasses.root]: {
                  color: "red",
                },
              }}
              menuItemStyles={{
                button: ({ level, active, disabled }) => {
                  if (level === 0)
                    return {
                      color: "#fff",
                      backgroundColor: active ? "#313c46" : undefined,
                    };
                  if (level === 1)
                    return {
                      color: disabled ? "#f5d9ff" : "#fff",
                      backgroundColor: collapsed ? "#111" : "transparent",
                    };
                },
              }}
            >
              {renderMenuItems(filterMenuList)}
            </Menu>
          </Scrollbars>
        </Sidebar>
        <div className="header_and_content_container">
          <HeaderBar
            title={title}
            show_title={showTitle}
            toggle={toggle}
            set_title={setTitle}
            collapsed={collapsed}
          />
          <Scrollbars
            id="scrollableElement"
            onScrollFrame={handleScroll}
            style={{ overflowX: "hidden" }}
          >
            <Content className="main_content">
              <Outlet />
            </Content>
          </Scrollbars>
        </div>
      </Layout>
    </div>
  );
});

export default AdminLayoutTemplate;
