import { Button } from "antd";
import React, { useState } from "react";
import EndPointForm from "./end_points_form";
import { IoMdClose } from "react-icons/io";
import CommonModal from "../../common/modal/custom_modal";

// Define the types for props
interface EndPointsModalProps {
  link_type: string;
  link_modal_open: boolean;
  handle_link_close: () => void;
  id: { id: string; name: string } | string; // Adjust based on the actual shape of `id`
  handle_submit: (e: React.FormEvent) => void; // Updated type
  action_item: any; // Define more specific type based on actual structure
  action_type: string;
}

const EndPointsModal: React.FC<EndPointsModalProps> = ({
  link_type,
  link_modal_open,
  handle_link_close,
  id,
  handle_submit,
  action_item,
  action_type,
}) => {
  const [loading, set_loading] = useState<boolean>(false);

  return (
    <CommonModal
      width={500}
      open={link_modal_open}
      onCancel={handle_link_close}
      title={
        link_type === "link_component" ? "Link Endpoint To Page Component" : ""
      }
      footer={
        <>
          <Button onClick={handle_link_close}>Cancel</Button>
          <Button
            form="link_form"
            htmlType="submit"
            onClick={handle_submit}
            loading={loading}
          >
            Link
          </Button>
        </>
      }
    >
      <EndPointForm
        get_id={
          typeof id === "string" ? { id, name: action_item?.name } : id || { id: "", name: "" }
        }
        link_type={link_type}
        set_loading={set_loading}
        action_item={action_item}
        close={handle_link_close}
        action_type={action_type}
      />
    </CommonModal>
  );
};

export default EndPointsModal;
