import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Table,
  Button,
  Descriptions,
  Form,
  Select,
} from "antd";
import LabelValuePair from "../common/label_value_pair";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../common/bread_crumb";
import { useNavigate, useParams } from "react-router-dom";
import { formatStatus } from "@src/helpers/status_constants";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  AppDispatch,
  delete_order_line_item,
  delete_order_line_item_request,
  get_order,
  get_order_line_item_list,
  get_order_line_item_list_request,
  get_order_request,
  update_order,
  update_order_clear,
  update_order_request,
} from "@src/redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import CommonModal from "../common/modal/custom_modal";
import OrderLineItemForm from "./order_line_item/order_line_item_form";
import { indian_currency_format } from "@src/helpers/constants";
import moment from "moment";
import DeleteConfirmationModal from "../common/modal/delete_modal";
import { useCheckPageComponentAccess } from "@src/hooks";
import { DEFAULT_IMAGE } from "@src/helpers/images_constants";

const { Text } = Typography;
interface StatusOption {
  label: string;
  value: string;
}

const CustomerOrderDetail: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const [order_Line_item, set_order_Line_item] = useState<any>({});
  const [order_Line_item_modal, set_order_Line_item_modal] =
    useState<boolean>(false);
  const [order_Line_item_delete_modal, set_order_Line_item_delete_modal] =
    useState<boolean>(false);
  const [order_status, set_order_status] = useState<any>("");
  const [payment_status_loading, set_payment_status_loading] =
    useState<boolean>(false);
  const [update_order_detail, set_update_order] = useState<boolean>(false);

  const { result: get_order_details } = useSelector(get_order);
  const {
    result: delete_order_line_result,
    loading: delete_order_line_loading,
  } = useSelector(delete_order_line_item);
  const { result: get_order_line_item_details } = useSelector(
    get_order_line_item_list
  );
  const { result: update_order_result, loading: update_order_loading } =
    useSelector(update_order);

  const handleBack = () => {
    navigate("/admin/customer-orders");
  };

  const handle_edit = (record: any) => {
    set_order_Line_item(record);
    set_order_Line_item_modal(true);
    set_order_Line_item_delete_modal(false);
    form.setFieldsValue(record);
  };

  const handle_add = (record: any) => {
    set_order_Line_item_modal(true);
    form.resetFields();
  };

  const handle_delete_item = () => {
    dispatch(delete_order_line_item_request({ id: order_Line_item?.id }));
  };

  const handle_delete = (record: any) => {
    set_order_Line_item(record);
    set_order_Line_item_delete_modal(true);

    set_order_Line_item_modal(false);
  };
  useEffect(() => {
    if (delete_order_line_result?.status === "success") {
      dispatch(get_order_line_item_list_request({ filter: { order_id: id } }));
      set_order_Line_item_delete_modal(false);
    }
    if (get_order_details) {
      set_order_status(get_order_details?.status);
    }
  }, [dispatch, delete_order_line_result, get_order_details]);
  useEffect(() => {
    dispatch(get_order_request({ filter: { id: id } }));
  }, [dispatch, id]);
  useEffect(() => {
    if (update_order_result?.status === "success") {
      set_payment_status_loading(false);
      set_update_order(false);
      dispatch(update_order_clear());
      dispatch(get_order_request({ filter: { id: id } }));
      toast.success("Order updated successfully");
    }
  }, [update_order_result]);

  useEffect(() => {
    if (get_order_details?.customer_id && get_order_details?.id) {
      dispatch(
        get_order_line_item_list_request({
          filter: {
            order_id: get_order_details?.id,
          },
        })
      );
    }
  }, [dispatch, get_order_details]);

  const handle_update_order = () => {
    if (id && order_status) {
      dispatch(
        update_order_request({
          id,
          data: {
            status: order_status === "placed" ? "in_progress" : "delivered",
          },
        })
      );
    }
  };
  const handle_update_payment_status = () => {
    if (id) {
      dispatch(
        update_order_request({
          id,
          data: {
            payment_status: "paid",
          },
        })
      );
    }
  };
  // const handle_cancel_order = () => {
  //   if (id && order_status) {
  //     dispatch(
  //       update_order_request({
  //         id,
  //         data: {
  //           status: "cancelled",
  //         },
  //       })
  //     );
  //   }
  // };

  const handle_close = () => {
    set_order_Line_item({});
    set_order_Line_item_modal(false);
    set_order_Line_item_delete_modal(false);
    form.resetFields();
  };

  const columns: any = [
    {
      title: "Product",
      dataIndex: "",
      key: "product",
      render: (record: any) => (
        <div className="category-container">
          <img
            src={record?.product?.image_list?.[0] || DEFAULT_IMAGE}
            className="product-image"
            alt={record?.product?.name}
          />
          <h4 className="category-name">{record?.product?.name}</h4>
        </div>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "variant",
      render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right" as const,
      render: (text: number) => <Text>{indian_currency_format(text)}</Text>,
    },
    {
      title: "Total",
      key: "price",
      align: "center" as const,
      render: (record: any) => (
        <Text>{indian_currency_format(record?.price * record?.quantity)}</Text>
      ),
    },
    // ...(order_status === "placed"
    //   ? [
    //       {
    //         title: "Actions",
    //         dataIndex: "",
    //         key: "actions",
    //         render: (record: any) => (
    //           <Row gutter={10}>
    //             <Col>
    //               <EditOutlined onClick={() => handle_edit(record)} />
    //             </Col>
    //             {/* <Col>
    //               <DeleteOutlined onClick={() => handle_delete(record)} />
    //             </Col> */}
    //           </Row>
    //         ),
    //       },
    //     ]
    //   : []),
  ];

  const items = [
    {
      title: t("customer_orders"),
      path: "/admin/customer-orders",
    },
    {
      title: get_order_details?.reference || "",
    },
  ];

  const Status_options: StatusOption[] = [
    {
      label: "Placed",
      value: "placed",
    },
    {
      label: "In Progress",
      value: "in_progress",
    },
    {
      label: "Delivered",
      value: "delivered",
    },
  ];

  return (
    <div style={{ padding: "24px" }}>
      <BreadCrumb items={items} />
      <Row gutter={16} className="customer-order-detail-container">
        <Col span={14}>
          <Card bordered={false} className="order-card">
            <Row justify={"space-between"}>
              <Col>
                <u>
                  <h3>{t("product_details")}</h3>
                </u>
              </Col>
              <Col>
                {/* {order_status === "placed" && (
                  <Button type="primary" onClick={handle_add}>
                    {t("add_product")}
                  </Button>
                )} */}
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={get_order_line_item_details?.items || []}
              pagination={false}
              className="admin-table"
              style={{ marginTop: "20px" }}
              summary={(record: any) => {
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell align="right" colSpan={4} index={0}>
                        <Text strong>GST: </Text>
                        <Text strong>
                          {indian_currency_format(
                            get_order_details?.total_gst
                          ) || "-"}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell align="right" colSpan={4} index={0}>
                        <Text strong>Total Amount: </Text>
                        <Text strong>
                          {indian_currency_format(
                            get_order_details?.total_amount
                          )}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Card>
        </Col>
        <Col span={10}>
          <Card bordered={false}>
            <LabelValuePair
              label="Name"
              value={get_order_details?.customer?.name || "-"}
            />
            <LabelValuePair
              label="Email"
              value={get_order_details?.customer?.email || "-"}
            />
            <LabelValuePair
              label="Mobile No"
              value={get_order_details?.customer?.mobile || "-"}
            />
            <LabelValuePair
              label="Credit Used"
              value={formatStatus(get_order_details?.credits_used || 0) || "-"}
            />
            <LabelValuePair
              label="Payment Status"
              value={formatStatus(get_order_details?.payment_status || "-")}
            />
            <LabelValuePair
              label="Order Status"
              value={formatStatus(get_order_details?.status || "-")}
            />

            <Row justify="end" gutter={10} style={{ marginTop: "20px" }}>
              {get_order_details?.payment_status === "pending" && (
                <Col>
                  {useCheckPageComponentAccess("in_progress_button") && (
                    <Button
                      style={{
                        backgroundColor: "green",
                        borderColor: "green",
                        color: "white",
                      }}
                      loading={payment_status_loading}
                      onClick={() => {
                        set_payment_status_loading(true);
                        handle_update_payment_status();
                      }}
                    >
                      {t("paid")}
                    </Button>
                  )}
                </Col>
              )}
              {order_status !== "delivered" && (
                <>
                  {order_status === "placed" && (
                    <Col>
                      {useCheckPageComponentAccess("in_progress_button") && (
                        <Button
                          type="primary"
                          loading={update_order_detail}
                          onClick={() => {
                            set_update_order(true);
                            handle_update_order();
                          }}
                        >
                          {t("in_progress")}
                        </Button>
                      )}
                    </Col>
                  )}
                  {order_status === "in_progress" && (
                    <Col>
                      {useCheckPageComponentAccess("delivered_button") && (
                        <Button
                          loading={update_order_detail}
                          onClick={() => {
                            set_update_order(true);
                            handle_update_order();
                          }}
                          style={{
                            backgroundColor: "green",
                            borderColor: "green",
                            color: "white",
                          }}
                        >
                          {t("delivered")}
                        </Button>
                      )}
                    </Col>
                  )}
                </>
              )}
            </Row>

            <br />

            <u>
              <h3>{t("delivery_address_details")}</h3>
            </u>
            <br />
            <LabelValuePair
              label="Name"
              value={get_order_details?.franchise?.name || "-"}
            />
            <LabelValuePair
              label="Address"
              value={get_order_details?.franchise?.address || "-"}
            />
            <LabelValuePair
              label="Mobile No"
              value={get_order_details?.franchise?.mobile || "-"}
            />
          </Card>
        </Col>
      </Row>
      <Row justify="end" gutter={10} style={{ marginTop: "20px" }}>
        <Col>
          {/* <Button
            type="primary"
            onClick={handle_update_order}
            loading={update_order_loading}
          >
            {t("update_order")}
          </Button> */}
        </Col>
        <Col>
          <Button danger onClick={handleBack}>
            {t("back")}
          </Button>
        </Col>
      </Row>

      {/* Edit Modal */}
      <CommonModal
        title={"Edit Product"}
        open={order_Line_item_modal}
        onCancel={handle_close}
      >
        <OrderLineItemForm
          form={form}
          line_item={order_Line_item}
          onClose={handle_close}
        />
      </CommonModal>

      {/* Delete Modal */}
      <DeleteConfirmationModal
        is_delete_modal_visible={order_Line_item_delete_modal}
        handle_delete={handle_delete_item}
        hide_delete_modal={handle_close}
        loading={delete_order_line_loading}
        item={order_Line_item?.product}
      ></DeleteConfirmationModal>
    </div>
  );
};

export default CustomerOrderDetail;
