import { combineReducers } from '@reduxjs/toolkit';
import create_system_ui_module_component_reducer from './create_system_ui_module_component';
import delete_system_ui_module_component_reducer from './delete_system_ui_module_component';
import update_system_ui_module_component_reducer from './update_system_ui_module_component';

const system_ui_module_component_reducer = combineReducers({
  create_system_ui_module_component: create_system_ui_module_component_reducer,
  delete_system_ui_module_component: delete_system_ui_module_component_reducer,
  update_system_ui_module_component: update_system_ui_module_component_reducer,
});

export default system_ui_module_component_reducer;
