import { Button, Row } from "antd";
import React, { useEffect } from "react";
import LabelValuePair from "../common/label_value_pair";
import { useTranslation } from "react-i18next";
import {
  AppDispatch,
  get_customer,
  get_customer_request,
  update_customer,
  update_customer_request,
} from "@src/redux";
import { useDispatch, useSelector } from "react-redux";
import { TiEdit } from "react-icons/ti";
import { useCheckPageComponentAccess } from "@src/hooks";
import CommonModal from "../common/modal/custom_modal";
import DynamicForm, {
  DynamicFormAction,
  DynamicFormField,
  DynamicFormSource,
} from "../general/dynamic_form";
import { useParams } from "react-router-dom";

const CustomerAboutTab = ({ setCustomerUpdateModal, customerUpdateModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const { result: customer } = useSelector(get_customer);

  const { result: customer_update_result } = useSelector(update_customer);
  useEffect(() => {
    if (customer_update_result?.status === "success") {
      dispatch(
        get_customer_request({
          filter: { id: id },
        })
      );
    }
  }, [customer_update_result]);

  const handleUpdate = () => {
    setCustomerUpdateModal(true);
  };

  const handleClose = () => {
    setCustomerUpdateModal(false);
  };

  const form_source: DynamicFormSource = {
    request: get_customer_request,
    selector: get_customer,
    params: {
      filter: { id: customer?.id },
    },
  };

  const form_actions: DynamicFormAction[] = [
    {
      type: "reset",
      label: "Reset",
    },
    {
      type: "cancel",
      label: "Cancel",
    },
    {
      type: "submit",
      label: "Save",
      update_source: {
        request: update_customer_request,
        selector: update_customer,
        params: {
          id: customer?.id || "",
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Customer updated successfully",
        response_failure_message: "Customer cannot be updated",
      },
    },
  ];

  const form_fields: DynamicFormField[] = [
    {
      name: "name",
      label: t("table:name"),
      type: "text",
      className: "name-box",
      rules: [
        { required: true, message: "Please enter Customer name" },
        {
          pattern: /^[a-zA-Z0-9]+$/,
          message: "Only letters and numbers allowed.",
        },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "mobile",
      label: t("table:mobile"),
      type: "text",
      className: "name-box",
      rules: [
        { required: true, message: "Please enter Mobile Number" },
        {
          pattern: /^\d{10}$/,
          message: "Mobile number should be 10 digits",
        },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "email",
      label: t("table:Email"),
      type: "text",
      className: "name-box",
      rules: [
        { required: true, message: "Please enter Email" },
        {
          type: "email",
          message: "Please enter valid Email",
        },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "address",
      label: t("table:Address"),
      type: "textarea",
      className: "name-box",
      rules: [{ required: true, message: "Please enter Full Address" }],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
  ];

  function on_modal_action(type: any, values: any) {
    console.log("action:", type);
  }
  return (
    <div>
      <Row align={"top"} justify={"end"}>
        {useCheckPageComponentAccess("update_customer_button") && (
          <Button
            icon={<TiEdit color="white" />}
            className="add-button"
            onClick={handleUpdate}
          >
            {t("update")}
          </Button>
        )}
      </Row>
      <div className="customer-about-main-container">
        <LabelValuePair label={t("name")} value={customer?.name} />
        <LabelValuePair label={t("mobile_number")} value={customer?.mobile} />
        <LabelValuePair label={t("mail_id")} value={customer?.email} />
        <LabelValuePair label={t("address")} value={customer?.address} />
        <LabelValuePair
          label={t("total_outlets")}
          value={customer?.franchise_list?.length}
        />
      </div>

      <DynamicForm
        view_type="modal"
        type={customerUpdateModal ? "edit" : "add"}
        modal_title={t("update_customer_details")}
        is_modal_open={customerUpdateModal}
        close_modal={handleClose}
        source={form_source}
        fields={form_fields}
        actions={form_actions}
        on_action={on_modal_action}
      />
    </div>
  );
};

export default CustomerAboutTab;
