// Logo and Profile Images
export const HEADER_LOGO = require("@src/assets/images/teaboy_logo_full.png");
export const HEADER_LOGO_WHITE = require("@src/assets/images/teaboy_logo_white.png");
export const PROFILE_WITHOUT_TEXT = require("@src/assets/images/T logo.png");
export const PROFILE = require("@src/assets/images/profile_img.jpg");
export const SIDEBAR = require("@src/assets/images/side_bar.png");
export const TEA = require("@src/assets/images/tea_image.jpg");
export const VERTACE_LOGO = require("@src/assets/images/vertace_logo.png");
export const DEFAULT_IMAGE = require("@src/assets/images/default_image.jpg");
export const HEADER_LOGO_BP = require("@src/assets/images/BP.png");
export const TEABOY = require("@src/assets/images/tea_boy_removebg.png");
export const BLACK_PEKOE = require("@src/assets/images/black_pekoe_tea.png");
