import { configureStore } from "@reduxjs/toolkit";
import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { retrieve_item } from "@src/helpers/functions";
import { BLACKPEAKOE_BASE_URL, TEABOY_BASE_URL } from "@src/helpers/constants";
import reducer from "./reducer";

let apiBaseUrl = TEABOY_BASE_URL;

export const setApiBaseUrl = async (url: string) => {
  apiBaseUrl = url;
  client.setLink(authLink.concat(createHttpLink()));
};

const createHttpLink = () =>
  new HttpLink({
    uri: () => {
      const brand = retrieve_item("brand");
      return brand === "teaboy" ? TEABOY_BASE_URL : BLACKPEAKOE_BASE_URL;
    },
  }); // Makes the URI dynamic

const authLink = new ApolloLink((operation, forward) => {
  const session_id = retrieve_item("session_id");
  operation.setContext({
    headers: {
      session_id: session_id ?? "",
    },
  });

  return forward(operation);
});
const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
export const client = new ApolloClient({
  link: authLink.concat(createHttpLink()),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: client,
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
