import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";

// Helper functions for encryption and decryption
export const encrypt_data = (data: string, salt: string): string =>
  CryptoJS.AES.encrypt(data, salt).toString();

export const decrypt_data = (
  cipher_text: string | null,
  salt: string
): string | null => {
  if (!cipher_text) return null;
  const bytes = CryptoJS.AES.decrypt(cipher_text, salt);
  try {
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch {
    return null;
  }
};

// Store item in local storage
export function store_item(key: string, data: any): void {
  const salt = process.env.REACT_APP_SALT || "";
  const value = JSON.stringify(data);
  const encryptedKey = window.btoa(key);
  const encryptedValue = encrypt_data(value, salt);
  window.localStorage.setItem(encryptedKey, encryptedValue);
  window.dispatchEvent(new Event(`${key}`));
}

// Retrieve item from local storage
export function retrieve_item(key: string): any | null {
  const salt = process.env.REACT_APP_SALT || "";
  try {
    const encryptedKey = window.btoa(key);
    const value = window.localStorage.getItem(encryptedKey);
    const decryptedValue = decrypt_data(value, salt);
    return decryptedValue ? JSON.parse(decryptedValue) : null;
  } catch {
    return null;
  }
}

// Clear all items in local storage
export function clear_local_storage(): void {
  try {
    window.localStorage.clear();
  } catch {
    // Handle errors if needed
  }
}

export function get_uuid(): string {
  return uuidv4();
}
