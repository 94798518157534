import moment, { Moment } from "moment";

export const TEABOY = "TeaBoy";
export const BLACK_PEKOE = "Black Pekoe";

// export const TEABOY_BASE_URL = "http://localhost:6227/graphql";
// export const BLACKPEAKOE_BASE_URL = "http://localhost:8234/graphql";
export const TEABOY_BASE_URL = "https://teaboy.vertace.org/api/graphql";
export const BLACKPEAKOE_BASE_URL =
  "https://blackpeakoe.vertace.org/api/graphql";

export const disable_date = (current: Moment | null): boolean => {
  return !!(current && current < moment().startOf("day"));
};

export const delivery_date_format = (
  date: string | Date | undefined
): string => {
  if (!date) return "";
  return moment(new Date(date)).format("DD MMM, dddd");
};

export const format_date = (date: string | Date | undefined): string => {
  if (!date) return "";
  return moment(new Date(date)).format("DD MMM, YYYY");
};

// Modified delivery_date_disable_condition with type safety
export const delivery_date_disable_condition = (
  current: Moment,
  validDates: string[] | undefined
): boolean => {
  const today = moment().startOf("day");
  const formatted_date = current.format("YYYY-MM-DD");
  return current.isBefore(today) || !validDates?.includes(formatted_date);
};

// Updated currency formatting function
export function indian_currency_format(
  amount: number = 0,
  show_zero: boolean = false
): string {
  let amountStr = amount.toFixed(2); // Ensure two decimal places
  let [integerPart, fractionalPart] = amountStr.split(".");

  // Format the integer part
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  if (otherDigits) {
    lastThreeDigits = "," + lastThreeDigits;
  }
  otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  let formattedInt = otherDigits + lastThreeDigits;

  // Combine the integer and fractional parts
  return (
    "₹" +
    formattedInt +
    (fractionalPart ? "." + fractionalPart : show_zero ? ".00" : "")
  );
}

export function count_format(amount: number = 0): string {
  let amountStr = amount.toFixed(2);
  let [integerPart, fractionalPart] = amountStr.split(".");

  // Format the integer part
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  if (otherDigits) {
    lastThreeDigits = "," + lastThreeDigits;
  }
  otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  let formattedInt = otherDigits + lastThreeDigits;

  return fractionalPart ? formattedInt + "." + fractionalPart : formattedInt;
}
