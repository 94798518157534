import {
  create_product,
  update_product_request,
  update_product,
  delete_product,
  delete_product_request,
  get_product_list,
  get_product_list_request,
  create_product_request,
  get_category_list_request,
  get_category_list,
  get_product_request,
  get_product,
  get_gst_list_request,
  get_gst_list,
  AppDispatch,
  update_product_clear,
  get_category,
  get_customer_list_request,
  get_customer_list,
} from "@src/redux";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable, {
  DynamicTableColumn,
  DynamicTableHandle,
} from "../components/general/dynamic_table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DynamicForm, {
  DynamicFilterField,
  DynamicFormAction,
  DynamicFormField,
  DynamicFormSource,
} from "../components/general/dynamic_form";
import { Button, Col, Row, Switch } from "antd";
import { t } from "i18next";
import PageTitle from "../components/common/page_title";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FaLock, FaUnlock } from "react-icons/fa";
import { useCheckPageComponentAccess } from "@src/hooks";
import { Form, InputNumber, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { DEFAULT_IMAGE } from "@src/helpers/images_constants";
import { Link } from "react-router-dom";

interface MeasurementType {
  label: string;
  value: any;
}

interface DayRange {
  from: number;
  to: number;
}

const Products: React.FC = () => {
  const table_ref = useRef<DynamicTableHandle>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [item_id, set_item_id] = useState(null);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [modal_type, set_modal_type] = useState<"add" | "edit">("add");
  const [gst_option, set_gst_option] = useState<any>([]);
  const { result: gst_list } = useSelector(get_gst_list);
  const { result: update_product_result, loading: update_customer_loading } =
    useSelector(update_product);

  const { t } = useTranslation();
  const measurement_type: MeasurementType[] = [
    {
      value: "piece",
      label: "Pcs",
    },
    {
      value: "gram",
      label: "Gram",
    },
    {
      value: "kilogram",
      label: "Kilogram",
    },
    {
      value: "litre",
      label: "Litre",
    },
    {
      value: "millilitre",
      label: "Millilitre",
    },
    {
      value: "bag",
      label: "Bag",
    },
    {
      value: "box",
      label: "Box",
    },
    {
      value: "packet",
      label: "Pocket",
    },
    {
      value: "case",
      label: "Case",
    },
    {
      value: "roll",
      label: "Roll",
    },
  ];
  useEffect(() => {
    dispatch(get_gst_list_request({}));
  }, []);

  useEffect(() => {
    if (gst_list) {
      const gst_item = gst_list?.items?.map((gst: any) => ({
        label: gst.percentage,
        value: gst.id,
      }));
      set_gst_option(gst_item);
    }
  }, [gst_list]);

  useEffect(() => {
    if (update_product_result?.status === "success") {
      if (table_ref.current) {
        table_ref.current.load_data();
      }
    }
  }, [update_product_result]);

  const form_fields: DynamicFormField[] = [
    {
      name: "image_list",
      label: t(" "),
      type: "image",
      rules: [{ required: true, message: "Please upload image." }],
      className: "image-upload",
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 22, sm: 22 },
      maxCount: 1,
    },
    {
      name: "name",
      label: t("table:name"),
      type: "text",
      className: "name-box",
      rules: [
        { required: true, message: "Please enter Products name" },
        {
          pattern: /^[a-zA-Z0-9]+$/,
          message: "Only letters and numbers allowed.",
        },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "category_id",
      label: t("table:category"),
      type: "select",
      rules: [{ required: true, message: "Please enter Products" }],
      source: {
        request: get_category_list_request,
        selector: get_category_list,
      },
      labelField: "name",
      valueField: "id",
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "hsn_code",
      label: t("table:hsn_code"),
      type: "text",
      className: "name-box",
      rules: [{ required: false, message: "Please enter hsn code" }],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },

    {
      name: "orderable_from_time_in_mins",
      label: t("table:orderable_from_time_in_mins"),
      type: "TimePicker",
      className: "name-box",
      rules: [{ required: true, message: "Please select item available time" }],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "orderable_till_time_in_mins",
      label: t("table:orderable_till_time_in_mins"),
      type: "TimePicker",
      className: "name-box",
      rules: [{ required: true, message: "Please select item closing time" }],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },

    {
      name: "orderable_dates",
      label: t("table:orderable_dates"),
      type: "dayRanges",
      className: "name-box",
      rules: [
        { required: true, message: "Please select item available dates" },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "price",
      label: t("table:price"),
      type: "number",
      rules: [{ required: true, message: "Please enter price" }],
      className: "name-box",
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "description",
      label: t("table:description"),
      type: "textarea",
      className: "name-box",
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "measurement_type",
      label: t("table:measurement_type"),
      type: "select",
      rules: [{ required: true, message: "Please select measurement type" }],
      options: measurement_type,
      className: "name-box",
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "gst_id",
      label: t("table:gst"),
      type: "select",
      className: "name-box",
      options: gst_option,
      rules: [{ required: true, message: "Please select gst" }],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
  ];

  const columns: DynamicTableColumn[] = [
    {
      title: "Name",
      sorter: true,
      sorted_by: "name",
      render: (record: any) => (
        <div className="product-container">
          <img
            src={record?.image_list?.[0] || DEFAULT_IMAGE} // Fallback image URL
            className="product-image"
            alt={record?.name || "Product Image"}
          />
          <Link to={`/admin/products/product-log/${record?.id}`}>
            {record?.name || "Unnamed Product"}
          </Link>
        </div>
      ),
    },
    {
      title: "Category",
      data_index: "category.name",
      sorter: true,
      sorted_by: "category.name",
    },
    {
      title: "HSN Code",
      data_index: "hsn_code",
      sorter: false,
    },
    {
      title: "Trending",
      sorter: false,
      render: (record: any) => (
        <Switch
          checked={record.is_trending}
          size="small"
          style={{
            boxShadow: "none",
            outline: "none",
            backgroundColor: record.is_trending ? "green" : "red",
          }}
          onChange={(value) => {
            dispatch(
              update_product_request({
                id: record?.id,

                data: { is_trending: value },
              })
            );
          }}
        />
      ),
    },
    {
      title: "Order From",
      data_index: "orderable_from_time_in_mins",
      sorter: true,
      render: (record: Date) => moment(record).format("hh:mm A"),
    },
    {
      title: "Order Till",
      data_index: "orderable_till_time_in_mins",
      sorter: true,
      render: (record: Date) => moment(record).format("hh:mm A"),
    },
    {
      title: "Actions",
      fixed: "right",
      action_access_key: "product_actions",
      actions: [
        {
          type: "edit",
          icon: <EditOutlined />,
          access_key: "update_product",
        },
        {
          type: "delete",
          icon: <DeleteOutlined />,
          access_key: "delete_product",
          title: "Are you sure you want to delete the Products {item_name}?",
          title_replace_field: "name",
          content: "Deleting this Products cannot be undone.",
          ok_text: "Yes",
          cancel_text: "No",
          source: {
            request: delete_product_request,
            selector: delete_product,
            filter_key: "id",
            filter_value_field: "id",
            response_path: "result.status",
            response_success_value: "success",
            response_success_message: "Products was successfully deleted",
            response_failure_message: "Products cannot be deleted",
          },
        },
      ],
    },
  ];

  const filters: DynamicFilterField[] = [
    {
      name: "category_id",
      label: "Category",
      type: "select",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
      source: {
        request: get_category_list_request,
        selector: get_category_list,
      },
    },
    {
      name: "name",
      label: " ",
      type: "search",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
  ];

  const form_source: DynamicFormSource = {
    request: get_product_request,
    selector: get_product,
    params: {
      filter: { id: item_id },
    },
  };

  const form_actions: DynamicFormAction[] = [
    {
      type: "reset",
      label: "Reset",
    },
    {
      type: "cancel",
      label: "Cancel",
    },
    {
      type: "submit",
      label: "Save",
      create_source: {
        request: create_product_request,
        selector: create_product,
        params: {
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Products created successfully",
        response_failure_message: "Products cannot be created",
      },
      update_source: {
        request: update_product_request,
        selector: update_product,
        params: {
          id: item_id || "",
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Products updated successfully",
        response_failure_message: "Products cannot be updated",
      },
    },
  ];

  function on_action(type: any, record?: any) {
    if (type === "edit") {
      set_item_id(record.id);
      set_modal_type(type);
      set_is_modal_open(true);
    } else {
      if (type === "add") {
        set_item_id(null);
        set_modal_type(type);
        set_is_modal_open(true);
      }
    }
  }

  function on_modal_action(type: any, values: any) {
    console.log("action:", type);
  }

  function close_modal(reload: boolean) {
    set_is_modal_open(false);
    set_item_id(null);
    set_modal_type("add");
    if (reload && table_ref.current) {
      table_ref.current.load_data();
    }
  }

  return (
    <>
      <div className="product-page-title-container">
        <div className="product-page-title">
          <PageTitle is_color={true} title={t("products")} />
        </div>

        <div className="add-drivers-button">
          {useCheckPageComponentAccess("add_product_button") && (
            <Button type="primary" onClick={() => on_action("add")}>
              {t("add_product")}
            </Button>
          )}
        </div>
      </div>
      <br />
      <DynamicTable
        ref={table_ref}
        columns={columns}
        filters={filters}
        source={{
          request: get_product_list_request,
          selector: get_product_list,
          params: {
            filter: {},
          },
        }}
        pagination={{
          page_size: 10,
          show_total: "Total {total} Products",
        }}
        on_action={on_action}
      />
      <DynamicForm
        view_type="modal"
        type={modal_type}
        modal_title={
          modal_type === "add" ? t("add_product") : t("edit_product")
        }
        is_modal_open={is_modal_open}
        close_modal={close_modal}
        source={form_source}
        fields={form_fields}
        actions={form_actions}
        on_action={on_modal_action}
      />
      {/* <Form form={form} onFinish={onFinish} autoComplete="off">
        <Form.List name="dayRanges" initialValue={[{ from: 1, to: 5 }]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "from"]}
                    fieldKey={[name, "from"]}
                    rules={[{ required: true, message: "From is required" }]}
                  >
                    <InputNumber placeholder="From" min={1} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "to"]}
                    fieldKey={[name, "to"]}
                    rules={[{ required: true, message: "To is required" }]}
                  >
                    <InputNumber placeholder="To" min={1} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Range
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form> */}
    </>
  );
};

export default Products;
