import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  VERTACE_LOGO,
  TEABOY,
  BLACK_PEKOE,
} from "@src/helpers/images_constants";
import { AppDispatch, dynamic_set, setApiBaseUrl } from "@src/redux";
import { BLACKPEAKOE_BASE_URL, TEABOY_BASE_URL } from "@src/helpers/constants";
import { store_item } from "@src/helpers/functions";
import { useDispatch } from "react-redux";

const Brands: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const brand_teaboy = () => {
    setApiBaseUrl(TEABOY_BASE_URL);
    store_item("brand", "teaboy");
    navigate("/admin/login");
    dispatch(dynamic_set("brand", { brand: "teaboy" }));
  };
  const brand_black_pekoe = () => {
    setApiBaseUrl(BLACKPEAKOE_BASE_URL);
    store_item("brand", "black_pekoe");
    navigate("/admin/login");
    dispatch(dynamic_set("brand", { brand: "black_pekoe" }));
  };
  return (
    <div className="login-page-main-container">
      <div className="card-container">
        <div className="brand-card" onClick={brand_teaboy}>
          <div
            className="card-content"
            style={{ backgroundImage: `url(${TEABOY})` }}
          ></div>
        </div>

        <div className="brand-card" onClick={brand_black_pekoe}>
          <div
            className="card-content"
            style={{ backgroundImage: `url(${BLACK_PEKOE})` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
