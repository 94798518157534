import { combineReducers } from '@reduxjs/toolkit';
import delete_dispute_line_item_reducer from './delete_dispute_line_item';
import get_dispute_line_item_reducer from './get_dispute_line_item';
import get_dispute_line_item_list_reducer from './get_dispute_line_item_list';
import update_dispute_line_item_reducer from './update_dispute_line_item';

const dispute_line_item_reducer = combineReducers({
  delete_dispute_line_item: delete_dispute_line_item_reducer,
  get_dispute_line_item: get_dispute_line_item_reducer,
  get_dispute_line_item_list: get_dispute_line_item_list_reducer,
  update_dispute_line_item: update_dispute_line_item_reducer,
});

export default dispute_line_item_reducer;
