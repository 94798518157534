import React from "react";
import { InputNumber } from "antd";
import { get_uuid } from "@src/helpers/functions";

// Define types for props
interface ProductInputBoxProps {
  set_product_stock: any;
  record: {
    id: string;
  };
  find_product_stock: any;
  product_stock: any;
  stock_value: any;
  set_stock_value: any;
  key: any;
}

interface Product {
  farmer_product: {
    product_id: string;
  };
  farmer_product_id: string;
  quantity: number;
}

const ProductInputBox: React.FC<ProductInputBoxProps> = ({
  record,
  find_product_stock,
  set_product_stock,
  product_stock,
  stock_value,
  set_stock_value,
  key,
}) => {
  const handle_change = (value: number | null) => {
    set_stock_value(value);
    const stock = Number(value);
    console.log(stock, "stockstockstockstockstockstock", find_product_stock);

    set_product_stock([
      {
        id: find_product_stock?.id,
        product_id: record?.id,
        quantity: stock,
      },
    ]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        justifyContent: "center",
        minHeight: "85px",
        alignItems: "start",
      }}
    >
      <InputNumber
        onChange={(value) => handle_change(value)}
        value={record?.id === key ? stock_value : null}
        style={{ width: "140px" }}
        controls={false}
        step={1}
        max={10000}
      />
    </div>
  );
};

export default ProductInputBox;
