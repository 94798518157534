import { Button } from "antd";
import React, { useState } from "react";
import PageForm from "./page_form";
import { IoMdClose } from "react-icons/io";
import CommonModal from "../../common/modal/custom_modal";

// Define the types for the props
interface PageModalProps {
  modal_open: boolean;
  handle_close: () => void;
  handle_submit: (e: React.FormEvent) => void; // Updated type
  action_type: String;
  id?: string;
  action_item?: any; // Replace `any` with a more specific type if available
  set_expanded_row_key?: (key: string | null) => void;
  link_type?: string;
}

const PageModal: React.FC<PageModalProps> = (props) => {
  const {
    modal_open,
    handle_close,
    handle_submit,
    action_type,
    id,
    action_item,
    set_expanded_row_key,
    link_type,
  } = props;

  const [loading, set_submit_loading] = useState<boolean>(false);

  return (
    <CommonModal
      title={
        action_type === "add_page"
          ? "Add Page"
          : action_type === "edit_page"
          ? "Edit Page"
          : action_type === "add_ui_component"
          ? "Add Page Component"
          : "Edit Page Component"
      }
      open={modal_open}
      onCancel={handle_close}
      footer={
        <>
          <Button onClick={handle_close}>Cancel</Button>
          <Button
            key="confirm"
            type="primary"
            htmlType="submit"
            form="page_form"
            onClick={handle_submit}
            loading={loading}
          >
            {action_type === "add_page"
              ? "Add"
              : action_type === "edit_page"
              ? "Update"
              : action_type === "add_ui_component"
              ? "Add"
              : "Update"}
          </Button>
        </>
      }
    >
      <PageForm
        close={handle_close}
        action_type={action_type || ""}
        get_id={id || ""}
        action_item={action_item}
        set_loading={set_submit_loading}
        set_expanded_row_key={set_expanded_row_key}
        link_type={link_type || null}
        // set_expanded_row_key={}
      />
    </CommonModal>
  );
};

export default PageModal;
