import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_order($filter: order_filter_input) {
    get_order(filter: $filter) {
      id
      customer_id
      franchise_id
      brand_fee
      total_gst
      total_amount
      reference
      payment_status
      status
      ordered_datetime
      customer {
        credits
        address
        email
        mobile
        name
        id
      }
      franchise {
        email
        mobile
        address
        name
        id
      }
      line_item_list {
        product {
          id
          name
          category_id
          image_list
          price
          description
        }
        updated_datetime
        price
        quantity
        product_id
        order_id
        id
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.OrderState = {
  result: null,
  error: null,
  loading: false,
};

export const get_order_request = createAsyncThunk<
  TYPES.Order,
  { filter?: TYPES.OrderFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "order/get_order",
  async (params: { filter?: TYPES.OrderFilterInput }, { extra: client }) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_order;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_order_slice = createSlice({
  name: "get_order",
  initialState: initial_state,
  reducers: {
    get_order_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_order_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_order_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_order_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_order_clear } = get_order_slice.actions;

export const get_order = createSelector(
  (state: RootState) => state.order.get_order.result,
  (state: RootState) => state.order.get_order.error,
  (state: RootState) => state.order.get_order.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_order_slice.reducer;
