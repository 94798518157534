import { combineReducers } from '@reduxjs/toolkit';
import create_order_reducer from './create_order';
import delete_order_reducer from './delete_order';
import get_order_reducer from './get_order';
import get_order_list_reducer from './get_order_list';
import update_order_reducer from './update_order';

const order_reducer = combineReducers({
  create_order: create_order_reducer,
  delete_order: delete_order_reducer,
  get_order: get_order_reducer,
  get_order_list: get_order_list_reducer,
  update_order: update_order_reducer,
});

export default order_reducer;
