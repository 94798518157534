import {
  AppDispatch,
  get_product_stock_log_list_request,
  get_product_stock_log_list,
} from "@src/redux";
import React, { useRef, useState } from "react";
import DynamicTable, {
  DynamicTableColumn,
  DynamicTableHandle,
} from "../components/general/dynamic_table";
import DynamicForm, {
  DynamicFilterField,
} from "../components/general/dynamic_form";
import PageTitle from "../components/common/page_title";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_IMAGE } from "@src/helpers/images_constants";
import { useParams } from "react-router-dom";

interface MeasurementType {
  label: string;
  value: any;
}

interface DayRange {
  from: number;
  to: number;
}

const StockLog: React.FC = () => {
  const table_ref = useRef<DynamicTableHandle>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [item_id, set_item_id] = useState(null);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [modal_type, set_modal_type] = useState<"add" | "edit">("add");
  const { id } = useParams();

  const { t } = useTranslation();

  const columns: DynamicTableColumn[] = [
    {
      title: "Name",
      //   sorter: true,
      sorted_by: "name",
      render: (record: any) => (
        <div className="product-container">
          <img
            src={record?.product?.image_list?.[0] || DEFAULT_IMAGE} // Fallback image URL
            className="product-image"
            alt={record?.product?.name || "Product Image"}
          />
          <h5 className="product-name">{`${
            record?.product?.name || "Unnamed Product"
          }`}</h5>
        </div>
      ),
    },
    {
      title: "Category",
      render: (record: any) => (
        <div className="product-container">
          <h5 className="product-name">{`${
            record?.product?.category?.name || "Unnamed Product"
          }`}</h5>
        </div>
      ),
    },
    {
      title: "Updated on",
      data_index: "created_datetime",
      sorter: true,
      render: (record: Date) => moment(record).format("DD/MM/YYYY, hh:mm A"),
    },
    {
      title: "Quantity",
      data_index: "quantity",
    },
  ];

  const filters: DynamicFilterField[] = [
    // {
    //   name: "category_id",
    //   label: "Category",
    //   type: "select",
    //   layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
    //   label_col: { xs: 24, sm: 24 },
    //   wrapper_col: { xs: 24, sm: 24 },
    //   source: {
    //     request: get_category_list_request,
    //     selector: get_category_list,
    //   },
    // },
    {
      name: "search_string",
      label: " ",
      type: "search",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
  ];

  function on_action(type: any, record?: any) {
    if (type === "edit") {
      set_item_id(record.id);
      set_modal_type(type);
      set_is_modal_open(true);
    } else {
      if (type === "add") {
        set_item_id(null);
        set_modal_type(type);
        set_is_modal_open(true);
      }
    }
  }

  return (
    <>
      <div className="product-page-title-container">
        <div className="product-page-title">
          <PageTitle is_color={true} title={t("product_stock_log")} />
        </div>

        <div className="add-drivers-button"></div>
      </div>
      <br />
      <DynamicTable
        ref={table_ref}
        columns={columns}
        // filters={filters}
        source={{
          request: get_product_stock_log_list_request,
          selector: get_product_stock_log_list,
          params: {
            filter: {
              product_id: id,
            },
            sort: [{ field: "created_datetime", order: "desc" }],
          },
        }}
        pagination={{
          page_size: 10,
          show_total: "Total {total} Products",
        }}
        on_action={on_action}
      />
    </>
  );
};

export default StockLog;
