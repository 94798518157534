import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { get_uuid } from "../../../../helpers/functions";
import PageName from "./page_name";
import {
  AppDispatch,
  create_system_ui_module,
  create_system_ui_module_clear,
  create_system_ui_module_component,
  create_system_ui_module_component_clear,
  create_system_ui_module_component_request,
  create_system_ui_module_request,
  get_system_ui_module_list_request,
  update_system_ui_module,
  update_system_ui_module_clear,
  update_system_ui_module_component,
  update_system_ui_module_component_clear,
  update_system_ui_module_component_request,
  update_system_ui_module_request,
} from "../../../../redux";

interface PageFormProps {
  close: () => void;
  action_type?: String;
  get_id: string;
  action_item: { name: string };
  set_loading: (loading: boolean) => void;
  set_expanded_row_key: any;
  link_type: string | null;
}

const PageForm: React.FC<PageFormProps> = ({
  close,
  action_type,
  get_id,
  action_item,
  set_loading,
  set_expanded_row_key,
  link_type,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();

  const { result, loading, error } = useSelector(
    create_system_ui_module_component
  );

  const {
    result: create_result,
    loading: create_loading,
    error: create_error,
  } = useSelector(create_system_ui_module);

  const {
    result: update_result,
    loading: update_loading,
    error: update_error,
  } = useSelector(update_system_ui_module);

  const {
    result: update_component_result,
    loading: update_component_loading,
    error: update_component_error,
  } = useSelector(update_system_ui_module_component);

  useEffect(() => {
    set_loading(
      create_loading || update_loading || loading || update_component_loading
    );
  }, [create_loading, update_loading, loading, update_component_loading]);

  useEffect(() => {
    if (action_type === "add_page" && create_result?.status === "success") {
      toast.success("Module created successfully");
      system_ui_module_list();
      close();
      dispatch(create_system_ui_module_clear());
    } else if (action_type === "add_page" && create_error?.message) {
      toast.error(create_error.message);
      system_ui_module_list();
      dispatch(create_system_ui_module_clear());
    } else if (
      action_type === "add_ui_component" &&
      result?.status === "success"
    ) {
      toast.success("Page Component created successfully");
      system_ui_module_list();
      close();
      set_expanded_row_key(null);
      dispatch(create_system_ui_module_component_clear());
    } else if (
      action_type === "add_ui_component" &&
      error?.message === "error"
    ) {
      toast.error(error.message);
      system_ui_module_list();
      set_expanded_row_key(null);
      dispatch(create_system_ui_module_component_clear());
    } else if (
      action_type === "edit_page" &&
      update_result?.status === "success"
    ) {
      toast.success("Page updated successfully");
      system_ui_module_list();
      close();
      dispatch(update_system_ui_module_clear());
    } else if (
      action_type === "edit_page" &&
      update_error?.message === "error"
    ) {
      toast.error(update_error.message);
      system_ui_module_list();
      dispatch(update_system_ui_module_clear());
    } else if (
      action_type === "edit_page_component" &&
      update_component_result?.status === "success"
    ) {
      toast.success("Page component updated successfully");
      system_ui_module_list();
      close();
      set_expanded_row_key(null);
      dispatch(update_system_ui_module_component_clear());
    } else if (
      action_type === "edit_page_component" &&
      update_component_error?.message
    ) {
      toast.error(update_component_error.message);
      system_ui_module_list();
      set_expanded_row_key(null);
      dispatch(update_system_ui_module_component_clear());
    }
  }, [
    create_result,
    create_error,
    result,
    error,
    update_result,
    update_component_result,
    update_component_error,
  ]);

  const system_ui_module_list = () => {
    const variable = {};
    dispatch(get_system_ui_module_list_request(variable));
  };

  useEffect(() => {
    system_ui_module_list();
  }, []);

  const create_page = (data: any) => {
    const variable = { data };
    dispatch(create_system_ui_module_request(variable));
  };

  const update_page = (data: any) => {
    const variable = { data, id: get_id };
    dispatch(update_system_ui_module_request(variable));
  };

  const create_page_component = (data: any) => {
    const variable = { data };
    dispatch(create_system_ui_module_component_request(variable));
  };

  const update_page_component = (data: any) => {
    dispatch(update_system_ui_module_component_request({ data, id: get_id }));
  };

  const on_finish = (values: any) => {
    const uuid = get_uuid();
    if (action_type === "add_page") {
      create_page({ ...values, id: uuid });
    } else if (action_type === "add_ui_component") {
      create_page_component({
        ...values,
        id: uuid,
        ui_module_id: get_id,
      });
    } else if (action_type === "edit_page") {
      update_page({
        ...values,
        id: get_id,
      });
    } else if (action_type === "edit_page_component") {
      update_page_component({
        ...values,
        id: get_id,
      });
    }
  };

  useEffect(() => {
    if (action_type === "add_page") {
      form?.resetFields();
      form.setFieldsValue({});
    } else {
      form.setFieldsValue({ name: action_item.name });
    }
  }, [action_item]);

  return (
    <div>
      <Form name="create page" onFinish={on_finish} id="page_form" form={form}>
        <PageName />
      </Form>
    </div>
  );
};

export default PageForm;
