import React from "react";

interface PageTitleProps {
  title: string;
  is_color: boolean;
}

const PageTitle: React.FC<PageTitleProps> = ({ title, is_color }) => {
  return (
    <h2
      style={{
        color: is_color ? "var(--layout-header)" : "#fff",
        fontWeight: "bold",
      }}
    >
      {title}
    </h2>
  );
};

export default PageTitle;
