import PageTitle from "../components/common/page_title";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable, {
  DynamicTableColumn,
  DynamicTableHandle,
} from "../components/general/dynamic_table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DynamicForm, {
  DynamicFilterField,
  DynamicFormAction,
  DynamicFormField,
  DynamicFormSource,
} from "../components/general/dynamic_form";
import { Button, Col, Row, Switch } from "antd";
import { useTranslation } from "react-i18next";
import {
  AppDispatch,
  create_customer,
  create_customer_request,
  delete_customer,
  delete_customer_request,
  get_category_list,
  get_category_list_request,
  get_customer,
  get_customer_list,
  get_customer_list_request,
  get_customer_request,
  get_district,
  get_district_list,
  get_district_list_request,
  get_state,
  get_state_list_request,
  update_customer,
  update_customer_clear,
  update_customer_request,
} from "@src/redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CommonModal from "../components/common/modal/custom_modal";
import { useDispatch, useSelector } from "react-redux";
import { FaLock, FaUnlock } from "react-icons/fa";

type Props = {};

const Customers = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { id: customer_id } = useParams();
  const table_ref = useRef<DynamicTableHandle>(null);
  const [item_id, set_item_id] = useState(null);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [customerCreateModal, setCustomerCreateModal] =
    useState<boolean>(false);
  const [modal_type, set_modal_type] = useState<"add" | "edit">("add");

  const { result: update_customer_result, loading: update_customer_loading } =
    useSelector(update_customer);

  const { t } = useTranslation();

  const handleCreate = () => {
    setCustomerCreateModal(true);
  };

  const handleClose = () => {
    setCustomerCreateModal(false);
  };

  useEffect(() => {
    if (update_customer_result?.status === "success") {
      dispatch(update_customer_clear());
      if (table_ref.current) {
        table_ref.current.load_data();
      }
    }
  }, [update_customer_result]);

  const form_fields: DynamicFormField[] = [
    {
      name: "name",
      label: t("table:name"),
      type: "text",
      className: "name-box",
      rules: [
        { required: true, message: "Please enter Customer name" },
        {
          pattern: /^[a-zA-Z0-9]+$/,
          message: "Only letters and numbers allowed.",
        },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "mobile",
      label: t("table:mobile"),
      type: "mobile",
      maxCount: 10,
      className: "name-box",
      rules: [
        { required: true, message: "Please enter Mobile Number" },
        {
          pattern: /^\d{10}$/,
          message: "Mobile number should be 10 digits",
        },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "email",
      label: t("table:email"),
      type: "text",
      className: "name-box",
      rules: [
        { required: true, message: "Please enter Email" },
        {
          type: "email",
          message: "Please enter valid Email",
        },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "password",
      label: t("table:password"),
      type: "text",
      className: "name-box",
      rules: [
        { required: true, message: "Please create password" },
        {
          pattern: /^.{6,12}$/,
          message: "Password must be between 6 and 12 characters.",
        },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "address",
      label: t("table:address"),
      type: "textarea",
      className: "name-box",
      rules: [{ required: true, message: "Please enter Full Address" }],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "district_id",
      label: t("table:District"),
      type: "select",
      className: "name-box",
      source: {
        selector: get_district_list,
        request: get_district_list_request,
      },
      rules: [{ required: true, message: "Please select District" }],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
  ];

  const columns: DynamicTableColumn[] = [
    {
      title: "Name",
      data_index: "",
      sorter: true,
      sorted_by: "name",
      render: (record: any) => (
        <Link to={`/admin/customer/${record.id}`}>{record?.name}</Link>
      ),
    },
    {
      title: "Lock",
      sorter: false,
      render: (record: any) => (
        <Switch
          checked={record.is_locked}
          checkedChildren={<FaLock style={{ color: "white" }} />}
          unCheckedChildren={<FaUnlock style={{ color: "white" }} />}
          size="default"
          style={{
            boxShadow: "none",
            outline: "none",
            backgroundColor: record.is_locked ? "red" : "green",
          }}
          onChange={(value) => {
            dispatch(
              update_customer_request({
                id: record?.id,

                data: { is_locked: value },
              })
            );
          }}
        />
      ),
    },

    {
      title: "Mobile",
      data_index: "mobile",
      sorter: true,
    },
    {
      title: "Email",
      data_index: "email",
      sorter: true,
    },
    {
      title: "Address",
      data_index: "address",
      // sorter: true,
    },
    {
      title: "District",
      data_index: "district.name",
      // sorter: true,
    },
    {
      title: "Credits",
      data_index: "credits",
      // sorter: true,
    },
    {
      title: "Actions",
      fixed: "right",
      action_access_key: "customer_actions",
      actions: [
        // {
        //   type: "edit",
        //   icon: <EditOutlined />,
        // },
        {
          type: "delete",
          icon: <DeleteOutlined />,
          access_key: "delete_customer",
          title: "Are you sure you want to delete the Customer {item_name}?",
          title_replace_field: "name",
          content: "Deleting this Customer cannot be undone.",
          ok_text: "Yes",
          cancel_text: "No",
          source: {
            selector: delete_customer,
            request: delete_customer_request,
            filter_key: "id",
            filter_value_field: "id",
            response_path: "result.status",
            response_success_value: "success",
            response_success_message: "Customer was successfully deleted",
            response_failure_message: "Customer cannot be deleted",
          },
        },
      ],
    },
  ];

  const filters: DynamicFilterField[] = [
    {
      name: "search_string",
      // label: "Search",
      type: "search",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
  ];

  const form_source: DynamicFormSource = {
    request: get_customer_request,
    selector: get_customer,
    params: {
      filter: { id: item_id },
    },
  };

  const form_actions: DynamicFormAction[] = [
    {
      type: "reset",
      label: "Reset",
    },
    {
      type: "cancel",
      label: "Cancel",
    },
    {
      type: "submit",
      label: "Save",
      create_source: {
        request: create_customer_request,
        selector: create_customer,
        params: {
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Customer created successfully",
        response_failure_message: "Customer cannot be created",
      },
      update_source: {
        request: update_customer_request,
        selector: update_customer,
        params: {
          id: item_id || "",
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Customer updated successfully",
        response_failure_message: "Customer cannot be updated",
      },
    },
  ];

  function on_action(type: any, record?: any) {
    if (type === "edit") {
      set_item_id(record.id);
      set_modal_type(type);
      navigate(`/admin/customer/${record.id}`);
    } else {
      if (type === "add") {
        set_item_id(null);
        set_modal_type(type);
        handleCreate();
      }
    }
  }

  function on_modal_action(type: any, values: any) {
    console.log("action:", type);
  }

  function close_modal(reload: boolean) {
    setCustomerCreateModal(false);

    set_is_modal_open(false);
    set_item_id(null);
    set_modal_type("add");
    if (reload && table_ref.current) {
      table_ref.current.load_data();
    }
  }

  return (
    <>
      <div className="category-page-title-container">
        <div className="vehicle-page-title">
          <PageTitle is_color={true} title={t("Customer") as any} />
        </div>

        <div className="add-drivers-button">
          <Button type="primary" onClick={() => on_action("add")}>
            {t("add_Customer") as any}
          </Button>
        </div>
      </div>
      <br />
      <DynamicTable
        ref={table_ref}
        columns={columns}
        filters={filters}
        source={{
          selector: get_customer_list,
          request: get_customer_list_request,
          params: {
            filter: {},
          },
        }}
        pagination={{
          page_size: 10,
          show_total: "Total {total} Customer",
        }}
        on_action={on_action}
      />
      <DynamicForm
        view_type="modal"
        type={modal_type}
        modal_title={
          modal_type === "add" ? t("create_customer") : t("edit_Customer")
        }
        is_modal_open={customerCreateModal}
        close_modal={close_modal}
        source={form_source}
        fields={form_fields}
        actions={form_actions}
        on_action={on_modal_action}
      />
    </>
  );
};

export default Customers;
