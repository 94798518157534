import React, { useEffect, useState } from "react";
import { Card, Col, Row, Avatar, Typography, Tabs, Skeleton } from "antd";
import { PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { LuPhone } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { PROFILE } from "@src/helpers/images_constants";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCheckPageComponentAccess } from "@src/hooks";
import {
  AppDispatch,
  create_franchise,
  get_customer,
  get_customer_request,
  update_customer,
  update_franchise,
} from "@src/redux";
import CustomerAboutTab from "../components/customer_detail/customer_about_tab";
import CustomerOutletsTab from "../components/customer_detail/customer_outlets_tab";
import CustomerOrdersTab from "../components/customer_detail/customer_order_tab";
import CustomerReturnTab from "../components/customer_detail/customer_return_tab";

const { Title, Text } = Typography;

const CustomerDetail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [customerUpdateModal, setCustomerUpdateModal] =
    useState<boolean>(false);
  const { id } = useParams();

  const { result: customer, loading: get_customer_loading } =
    useSelector(get_customer);
  const { result: create_franchise_result } = useSelector(create_franchise);
  const { result: update_franchise_result } = useSelector(update_franchise);

  useEffect(() => {
    if (id) {
      get_one_customer();
    }
  }, [id, update_franchise_result, create_franchise_result]);

  const get_one_customer = () => {
    dispatch(
      get_customer_request({
        filter: { id: id },
      })
    );
  };

  const tab_items = [
    {
      key: "customer_about",
      page_component_key: "customer_tab",
      label: t("about_customer"),
      children: (
        <CustomerAboutTab
          customerUpdateModal={customerUpdateModal}
          setCustomerUpdateModal={setCustomerUpdateModal}
        />
      ),
    },
    {
      key: "customer_outlets",
      page_component_key: "customer_outlets_tab",
      label: t("customer_outlets"),
      children: <CustomerOutletsTab />,
    },
    {
      key: "customer_orders",
      page_component_key: "customer_orders_tab",
      label: t("customer_orders"),
      children: <CustomerOrdersTab />,
    },
    {
      key: "customer_returns",
      page_component_key: "customer_returns_tab",
      label: t("customer_returns"),
      children: <CustomerReturnTab />,
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      {!get_customer_loading ? (
        <Card>
          <Row gutter={16}>
            <Col span={4}>
              <Avatar size={100} src={PROFILE} />
            </Col>
            <Col span={18}>
              <Title level={4}>{customer?.name || ""}</Title>
              <Text>
                <LuPhone /> {customer?.mobile || ""}
              </Text>
              <br />
              <Text>
                <EnvironmentOutlined /> {customer?.address || ""}
              </Text>
            </Col>
          </Row>
        </Card>
      ) : (
        <Card>
          <Row gutter={16}>
            <Col span={4}>
              <Skeleton.Avatar size={100} />
            </Col>
            <Col span={18}>
              <Skeleton style={{ width: "200px" }} />
            </Col>
          </Row>
        </Card>
      )}
      <Tabs
        defaultActiveKey="customer_tab"
        style={{ marginTop: "20px" }}
        items={tab_items}
        className="customer-tabs"
        type="card"
      />
    </div>
  );
};

export default CustomerDetail;
