import React, { useEffect } from "react";
import LoginForm from "../components/login/login_form";

const AdminLogin: React.FC = () => {
  return (
    <div className="login-page-main-container">
      <LoginForm />
    </div>
  );
};

export default AdminLogin;
