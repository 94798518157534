import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";

interface BreadCrumbItem {
  path?: string;
  title?: string;
}

interface BreadCrumbProps {
  items: BreadCrumbItem[];
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ items }) => {
  const generateBreadcrumbs = () => {
    return items.map((item, index) => {
      const isLast = index === items.length - 1;
      return (
        <Breadcrumb.Item className="breadcrumb_content" key={item.path}>
          {isLast ? (
            item.title
          ) : (
            <Link className="breadcrumb_link" to={item?.path || ""}>
              {item.title}
            </Link>
          )}
        </Breadcrumb.Item>
      );
    });
  };

  return (
    <Breadcrumb className="bread-crumb">{generateBreadcrumbs()}</Breadcrumb>
  );
};

export default BreadCrumb;
