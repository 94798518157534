import { Tag } from "antd";
import { startCase } from "lodash";

type Status =
  | "demand"
  | "active"
  | "Completed"
  | "delivered"
  | "Approved"
  | "Failure"
  | "rejected"
  | "closed"
  | "Pending"
  | "on_queue"
  | "Reverted"
  | "failed"
  | "duplicate"
  | "processed_with_error"
  | "processing"
  | "skip"
  | "credit"
  | "debit"
  | string
  | undefined;

interface FormatStatusProps {
  value?: String;
}

export const formatStatus: React.FC = (value: any) => {
  switch (value) {
    case "demand":
      return (
        <Tag className={`status-tag status-demand`}>{startCase(value)}</Tag>
      );
    case "active":
      return <Tag className="status-tag status-active">{startCase(value)}</Tag>;
    case "cancelled":
      return <Tag className="status-tag status-closed">{startCase(value)}</Tag>;
    case "completed":
      return <Tag className="status-tag status-active">{startCase(value)}</Tag>;
    case "delivered":
      return <Tag className="status-tag status-active">{startCase(value)}</Tag>;
    case "success":
      return <Tag className="status-tag status-active">{startCase(value)}</Tag>;
    case "paid":
      return <Tag className="status-tag status-active">{startCase(value)}</Tag>;
    case "credit":
      return <Tag className="status-tag status-active">{startCase(value)}</Tag>;
    case "debit":
      return <Tag className="status-tag status-closed">{startCase(value)}</Tag>;
    case "placed":
      return <Tag className="status-tag status-demand">{startCase(value)}</Tag>;
    case "in_progress":
      return (
        <Tag className="status-tag status-progress">{startCase(value)}</Tag>
      );
    case "order_placed":
    case "submitted":
      return <Tag className="status-tag status-demand">{startCase(value)}</Tag>;
    case "accepted":
      return <Tag className="status-tag status-active">{startCase(value)}</Tag>;
    case "placed":
      return <Tag className="status-tag status-demand">{startCase(value)}</Tag>;
    case "Approved":
      return <Tag className="status-tag status-active">{startCase(value)}</Tag>;
    case "Failure":
      return <Tag className="status-tag status-closed">{startCase(value)}</Tag>;
    case "pending":
      return <Tag className="status-tag status-closed">{startCase(value)}</Tag>;
    case "rejected":
      return <Tag className="status-tag status-closed">{startCase(value)}</Tag>;
    case "closed":
    case "initiated":
      return (
        <Tag className="status-tag status-pending">{startCase(value)}</Tag>
      );
    case "un_paid":
      return <Tag className="status-tag status-closed">{startCase(value)}</Tag>;
    case "Pending":
    case "on_queue":
      return (
        <Tag className="status-tag status-pending">{startCase(value)}</Tag>
      );
    case "Reverted":
    case "failed":
      return <Tag className="status-tag status-revert">{startCase(value)}</Tag>;
    case "duplicate":
      return <Tag className="status-tag status-revert">{startCase(value)}</Tag>;
    case "processed_with_error":
      return <Tag className="status-tag status-revert">{startCase(value)}</Tag>;
    case "processing":
      return (
        <Tag className="status-tag status-pending">{startCase(value)}</Tag>
      );
    case "skip":
      return (
        <Tag className="status-tag status-pending">{startCase(value)}</Tag>
      );
    default:
      return value ? startCase(value) : "-";
  }
};
