import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_order_line_item($filter: order_line_item_filter_input) {
    get_order_line_item(filter: $filter) {
      id
      order_id
      product_id
      quantity
      price
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.OrderLineItemState = {
  result: null,
  error: null,
  loading: false,
};

export const get_order_line_item_request = createAsyncThunk<
  TYPES.OrderLineItem,
  { filter?: TYPES.OrderLineItemFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'order_line_item/get_order_line_item',
  async (
    params: { filter?: TYPES.OrderLineItemFilterInput },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_order_line_item;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_order_line_item_slice = createSlice({
  name: 'get_order_line_item',
  initialState: initial_state,
  reducers: {
    get_order_line_item_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_order_line_item_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_order_line_item_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_order_line_item_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { get_order_line_item_clear } = get_order_line_item_slice.actions;

export const get_order_line_item = createSelector(
  (state: RootState) => state.order_line_item.get_order_line_item.result,
  (state: RootState) => state.order_line_item.get_order_line_item.error,
  (state: RootState) => state.order_line_item.get_order_line_item.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_order_line_item_slice.reducer;
