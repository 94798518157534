import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

interface Item {
  id?: number;
  name?: string;
}

interface DeleteConfirmationModalProps {
  is_delete_modal_visible: boolean;
  handle_delete: (id?: any) => void;
  hide_delete_modal: () => void;
  item?: Item;
  loading?: boolean;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  is_delete_modal_visible,
  handle_delete,
  hide_delete_modal,
  item,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        bodyStyle={{ margin: "20px" }}
        title={
          <div style={{ padding: "10px 20px" }}>{t("confirm_delete")}</div>
        }
        open={is_delete_modal_visible}
        onOk={() => {
          handle_delete(item?.id);
        }}
        onCancel={hide_delete_modal}
        okText={t("yes")}
        cancelText={t("no")}
        confirmLoading={loading}
      >
        <p>{`Are you sure you want to delete this ${item?.name}?`}</p>
      </Modal>
    </div>
  );
};

export default DeleteConfirmationModal;
