import { DEFAULT_IMAGE } from "@src/helpers/images_constants";
import React from "react";

interface ProductRecord {
  image_list?: string[];
  name?: string;
}

interface ProductProps {
  record: ProductRecord;
}

const Product: React.FC<ProductProps> = ({ record }) => {
  return (
    <div className="product-container">
      <img
        src={record?.image_list?.[0] || DEFAULT_IMAGE} // Fallback image URL
        className="product-image"
        alt={record?.name || "Product Image"}
      />
      <h5 className="product-name">{`${record?.name || "Unnamed Product"}`}</h5>
    </div>
  );
};

export default Product;
