import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { FaEdgeLegacy } from "react-icons/fa";

// Define the prop types
interface UIModulePageProps {
  handle_edit: (record: any) => void;
  handle_link: (record: any) => void;
  handle_delete: (record: any) => void;
  setIsHovered: (isHovered: boolean) => void;
  key: any;
  expandedRowKey?: any;
  record: {
    name: string;
    [key: string]: any;
  };
  isHovered: boolean;
}

const UIModulePage: React.FC<UIModulePageProps> = (props) => {
  const [currentId, setCurrentId] = useState<string | null>(null);

  const {
    handle_edit,
    handle_link,
    handle_delete,
    setIsHovered,
    key,
    expandedRowKey,
    record,
    isHovered,
  } = props;

  const menu = (
    <Menu>
      <Menu.Item key="add" onClick={() => handle_link(record)}>
        <FaEdgeLegacy style={{ marginRight: "5px", color: "green" }} />
        Link
      </Menu.Item>
      <Menu.Item key="edit" onClick={() => handle_edit(record)}>
        <AiOutlineEdit style={{ marginRight: "5px", color: "blue" }} />
        Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handle_delete(record)}>
        <AiOutlineDelete style={{ marginRight: "5px", color: "red" }} />
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      key={key}
    >
      <Dropdown overlay={menu} trigger={["hover"]}>
        <div style={{ position: "relative" }}>{record?.name}</div>
      </Dropdown>
    </div>
  );
};

export default UIModulePage;
