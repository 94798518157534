import React, { useEffect, useState } from "react";
import lodash from "lodash";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Col,
  Row,
  Button,
  Card,
  DatePicker,
  Checkbox,
  Radio,
  Switch,
  Upload,
  message,
  Modal,
  TimePicker,
  Space,
} from "antd";
import { useDispatch } from "react-redux";
import {
  AppDispatch,
  get_signed_url,
  get_signed_url_clear,
  get_signed_url_request,
  // get_signed_url,
  // get_signed_url_clear,
  // get_signed_url_request,
} from "@src/redux";
import store from "@src/redux/store";
import { Grid } from "antd";
import CommonModal from "../../common/modal/custom_modal";
import { x64 } from "crypto-js";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { UploadFile } from "antd/lib";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import {
  action,
  handle_before_upload,
  handle_file_changed,
  handle_upload,
} from "@src/helpers/file_upload";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

export interface DynamicFormSource {
  request: Function;
  selector: any;
  params: {
    filter?: any | {};
  };
}

export interface DynamicFormField {
  name: string; // Optional for card
  exclude?: boolean;
  label?: string; // Optional for card
  type?:
    | "text"
    | "number"
    | "mobile"
    | "select"
    | "password"
    | "otp"
    | "textarea"
    | "checkbox"
    | "radio"
    | "date"
    | "range"
    | "image"
    | "TimePicker"
    | "dayRanges"
    | "switch"; // Added new types
  mode?: "multiple" | "tags"; // Add this line to support mode
  className?: string;
  rules?: {
    required?: boolean;
    message?: string;
    type?: "email" | "number";
    pattern?: RegExp;
  }[]; // Optional for card
  enabledDates?: string[];
  minDate?: string;
  maxDate?: string;
  layout?: {
    span: number;
    xs?: number; // Extra small screens
    sm?: number; // Small screens
    md?: number; // Medium screens
    lg?: number; // Large screens
    xl?: number; // Extra large screens
    xxl?: number; // Extra extra large screens
  };
  labelCol?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  };
  wrapperCol?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  };
  source?: {
    request: Function; // Thunk action or function to fetch data
    selector: Function; // Selector to extract data from state
  };
  options?: { label: string; value: any }[]; // For checkbox, radio// select
  labelField?: string; // Custom label field name
  valueField?: string; // Custom value field name
  dependsOn?: {
    field: string; // Name of the field this field depends on
    filterKey: string; // Key in the filter object for dependency
    value?: string; // Value to set in the filter object
    is_filter?: boolean; // Whether to include in the filter object
  };
  filter?: any; // Additional filter options for data fetching
  card?: {
    title: string; // Title of the card
    extra?: React.ReactNode; // Extra content for the card header
    bordered?: boolean; // Whether to show a border around the card
    cover?: React.ReactNode;
    className?: string;
  };
  children?: DynamicFormField[];
  maxCount?: number;
  length?: number;
  maxValue?: number;
}

export interface DynamicFormAction {
  label: string; // Optional for card
  type: "reset" | "cancel" | "submit";
  class_name?: string;
  create_source?: {
    request: Function;
    selector: Function;
    params: {
      data: any | {};
    };
    response_path: string;
    response_success_value: string;
    response_success_message: string;
    response_failure_message: string;
  };
  update_source?: {
    request: Function;
    selector: Function;
    params: {
      id: string;
      data: any | {};
    };
    response_path: string;
    response_success_value: string;
    response_success_message: string;
    response_failure_message: string;
  };
}

export interface DynamicFilterField {
  name: string;
  label?: string;
  type:
    | "text"
    | "number"
    | "select"
    | "checkbox"
    | "radio"
    | "date"
    | "range"
    | "search"
    | "switch";
  class_name?: string;
  enabled_dates?: string[];
  min_date?: string;
  max_date?: string;
  layout?: {
    span: number;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  };
  label_col?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  };
  wrapper_col?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  };
  source?: {
    request: Function;
    selector: Function;
  };
  options?: { label: string; value: any }[];
  label_field?: string;
  value_field?: string;
  depends_on?: {
    field: string; // Name of the field this field depends on
    filter_key: string; // Key in the filter object for dependency
    value?: string; // Key in the filter object for dependency
  };
  filter?: any;
}

interface DynamicFormProps {
  view_type: "page" | "modal";
  modal_title?: string | null;
  is_modal_open?: boolean | null;
  close_modal?: any;
  type: "add" | "edit";
  source: DynamicFormSource;
  fields: DynamicFormField[];
  actions: DynamicFormAction[];
  on_action: (type: any, values?: any) => void;
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  view_type,
  type,
  is_modal_open,
  modal_title,
  close_modal,
  source,
  fields,
  actions,
  on_action,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const [select_options, set_select_options] = useState<Record<string, any>>(
    {}
  );
  const [filters, set_filters] = useState<Record<string, any>>({});
  const [item, set_item] = useState<Record<string, any>>({});
  const [submit_loading, set_submit_loading] = useState<boolean>(false);
  const load_item = async (action_type: string) => {
    if (action_type === "edit") {
      const { request, selector, params } = source;
      await dispatch(request(params)).unwrap();
      const state = store.getState();
      const response = selector(state);

      let _item = response?.result;

      let dynamicFields: any = { ..._item };

      for (let key in _item) {
        if (Array.isArray(_item[key])) {
          if (
            _item[key].every(
              (x: any) => x && typeof x === "object" && "id" in x
            )
          ) {
            dynamicFields[key] = _item[key].map((x: any) => x.id);
          }
        }
      }
      fields.forEach((field: DynamicFormField) => {
        if (field.type === "image") {
          if (_item[field?.name] && Array.isArray(_item[field?.name])) {
            dynamicFields[field.name] = _item[field.name].map(
              (url: string) => ({
                uid: get_uuid(),
                name: "view",
                url: url,
              })
            );
          } else {
            dynamicFields[field.name] = {
              uid: get_uuid(),
              name: "view",
              url: _item[field.name],
            };
          }
        } else if (field.type === "TimePicker") {
          dynamicFields[field.name] = dayjs(new Date(_item[field.name]));
        }
      });

      form.setFieldsValue(dynamicFields);
      set_item(_item);
    } else {
      form.resetFields();
    }
  };

  const load_data = async () => {
    if (type === "edit") {
      const { request, selector, params } = source;
      await dispatch(request(params)).unwrap();
      const state = store.getState();
      const data = selector(state);

      let source_path = "result.items";
      const items =
        source_path.split(".").reduce((acc: any, part) => acc?.[part], data) ||
        [];
      try {
        let pagination_path = "result.pagination";
        const pagination_result =
          pagination_path
            .split(".")
            .reduce((acc: any, part) => acc?.[part], data) || [];
        if (items.length === 0 && pagination_result.page_number > 0) {
          const total_pages = Math.ceil(
            pagination_result?.total_count / pagination_result?.page_limit
          );
          return;
        }
      } catch (e) {}

      set_item(items?.[0]);
    } else {
      form.resetFields();
    }
  };

  const load_select_options = async () => {
    const flattened_fields = flatten_children(fields);

    const promises = flattened_fields
      .filter(
        (field) =>
          (field.type === "select" ||
            field.type === "checkbox" ||
            field.type === "radio") &&
          field.source
      )
      .map(async (field) => {
        const { request, selector } = field.source!;
        try {
          let current_filter = filters[field.name || ""] || undefined;
          let _selectOption = select_options[field.name || ""];
          if (
            current_filter?.reference &&
            current_filter?.reference === _selectOption?.reference
          ) {
            return;
          }
          if (!current_filter && field?.dependsOn) {
            return;
          }
          await dispatch(request(current_filter?.filter)).unwrap();

          const state = store.getState();
          const options = selector(state);

          let result: any = {
            options: options?.result?.items,
            reference: current_filter?.reference || get_uuid(),
          };

          set_select_options((prev) => ({
            ...prev,
            [field.name || ""]: result,
          }));
        } catch (error) {
          console.error(`Failed to load options for ${field.name}:`, error);
        }
      });

    await Promise.all(promises);
  };

  function flatten_children(items: any[]) {
    const result: any[] = [];

    function recurse(children: any[]) {
      children.forEach((item) => {
        if (item.children) {
          result.push(...item.children);
          recurse(item.children);
        } else {
          result.push(item);
        }
      });
    }

    recurse(items);
    return result;
  }

  useEffect(() => {
    setTimeout(() => {
      load_item(type);
      // load_data();
    }, 200);
  }, [type]);

  useEffect(() => {
    load_select_options();
  }, [filters]);

  function get_uuid(buf: number = 0): string {
    const now: Date = new Date();
    const time: number = now.getTime();
    let offset: number = now.getTimezoneOffset();
    offset = offset * 60000;
    let dt: string = `${time - offset + buf}`;
    const uuid: string = `${dt.substr(0, 8)}-${dt.substr(8, 4)}-${dt.substr(
      12,
      1
    )}xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
      const r: number = (parseInt(dt, 10) + Math.random() * 16) % 16 | 0;
      dt = Math.floor(parseInt(dt, 10) / 16).toString();
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const handle_field_change = (fieldName: string, value: any) => {
    const flattened_fields = flatten_children(fields);
    const dependant_fields = flattened_fields.filter(
      (f: any) => f.dependsOn?.field === fieldName
    );
    if (dependant_fields.length > 0) {
      let _filter = { ...filters };
      dependant_fields.forEach((dependant_field: any) => {
        if (dependant_field.dependsOn) {
          const result = create_json_object(
            dependant_field.dependsOn.filterKey,
            value
          );
          if (!_filter[dependant_field.name]) {
            _filter[dependant_field.name] = {};
          }
          _filter[dependant_field.name].reference = get_uuid();
          _filter[dependant_field.name].filter = result;
          form.setFieldsValue({ [dependant_field.name]: null });
        }
      });
      set_filters(_filter);
    }

    form.setFieldsValue({ [fieldName]: value });
  };

  function create_json_object(key: string, value: any) {
    const obj = {};
    lodash.set(obj, key, value);
    return obj;
  }

  const render_field = (field: DynamicFormField) => {
    const {
      name,
      label,
      type,
      rules,
      layout,
      dependsOn,
      card,
      children,
      className,
      mode,
      maxCount,
      maxValue,
    } = field;
    const fieldProps = { name, label, rules, maxCount, maxValue };
    console.log("select_options", select_options);

    if (card) {
      return (
        <Col span={24} key={card.title}>
          <Card
            title={card.title}
            extra={card.extra}
            bordered={card.bordered}
            cover={card.cover}
            className={card.className}
          >
            <Row gutter={16}>{children?.map(render_field)}</Row>
          </Card>
          <br />
        </Col>
      );
    }
    let colSpan = 24;
    if (screens.xl) colSpan = layout?.xl || layout?.span || 24;
    if (screens.lg) colSpan = layout?.lg || layout?.xl || layout?.span || 24;
    if (screens.md)
      colSpan = layout?.md || layout?.lg || layout?.xl || layout?.span || 24;
    if (screens.sm)
      colSpan =
        layout?.sm ||
        layout?.md ||
        layout?.lg ||
        layout?.xl ||
        layout?.span ||
        24;
    if (screens.xs)
      colSpan =
        layout?.xs ||
        layout?.sm ||
        layout?.md ||
        layout?.lg ||
        layout?.xl ||
        layout?.span ||
        24;

    const disabledDate = (currentDate: any) => {
      if (field.enabledDates && field.enabledDates.length > 0) {
        currentDate = currentDate ? currentDate.format("YYYY-MM-DD") : null;
        return !field.enabledDates.includes(currentDate);
      }
      if (field.minDate && currentDate.isBefore(field.minDate, "day")) {
        return true;
      }
      if (field.maxDate && currentDate.isAfter(field.maxDate, "day")) {
        return true;
      }
      return false;
    };
    let imageFileUploadresponse = useSelector(get_signed_url);

    if (type === "image") {
      const file_list = Form.useWatch(fieldProps.name, form) || [];
      let upload_file_list = Array.isArray(file_list)
        ? file_list
        : file_list.fileList || [];
      const handleRemove = (file: any) => {
        const updatedFileList = file_list?.fileList?.filter(
          (item: any) => item.uid !== file.uid
        );

        form.setFieldsValue({
          [fieldProps.name]: updatedFileList,
        });
      };

      console.log(upload_file_list, "upload_file_list");

      return (
        <Col span={colSpan} key={name}>
          <Form.Item
            {...fieldProps}
            colon={false}
            labelCol={field.labelCol}
            wrapperCol={field.wrapperCol}
            className={className}
          >
            <Upload
              maxCount={fieldProps.maxCount}
              action={action}
              fileList={
                // file_list?.fileList?.length > 0
                //   ? file_list.fileList
                //   : file_list || []
                upload_file_list
              }
              listType="picture-card"
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: true,
              }}
              customRequest={(e: any) => handle_upload(e)}
              beforeUpload={handle_before_upload}
              onChange={handle_file_changed}
              onRemove={handleRemove}
            >
              {file_list?.length === (fieldProps.maxCount || 0) ? null : (
                <Button
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  icon={<UploadOutlined />}
                >
                  Upload
                </Button>
              )}
            </Upload>
          </Form.Item>
        </Col>
      );
    }
    return (
      <Col span={colSpan} key={name}>
        <Form.Item
          {...fieldProps}
          colon={false}
          labelCol={field.labelCol}
          wrapperCol={field.wrapperCol}
          className={className}
        >
          {type === "text" && <Input maxLength={maxCount || undefined} />}
          {type === "number" && (
            <InputNumber
              controls={false}
              min={1}
              changeOnWheel={false}
              style={{ width: "100%" }}
              max={maxValue}
              maxLength={maxCount || undefined}
            />
          )}
          {type === "mobile" && (
            <InputNumber
              controls={false}
              changeOnWheel={false}
              min={1}
              style={{ width: "100%" }}
              maxLength={maxCount || undefined}
            />
          )}
          {type === "password" && <Input.Password />}
          {type === "otp" && <Input.OTP />}
          {type === "textarea" && <TextArea />}
          {type === "checkbox" && (
            <Checkbox.Group options={field.options}>
              {field.options?.map((option: any) => (
                <Checkbox
                  key={option[field.valueField || "id"]}
                  value={option[field.valueField || "id"]}
                >
                  {option[field.labelField || "name"]}
                </Checkbox>
              ))}
              {select_options[name || ""]?.options?.map((option: any) => (
                <Checkbox
                  key={option[field.valueField || "id"]}
                  value={option[field.valueField || "id"]}
                >
                  {option[field.labelField || "name"]}
                </Checkbox>
              ))}
            </Checkbox.Group>
          )}
          {type === "radio" && (
            <Radio.Group>
              {field.options?.map((option: any) => (
                <Radio
                  key={option[field.valueField || "id"]}
                  value={option[field.valueField || "id"]}
                >
                  {option[field.labelField || "name"]}
                </Radio>
              ))}
              {select_options[name || ""]?.options?.map((option: any) => (
                <Radio
                  key={option[field.valueField || "id"]}
                  value={option[field.valueField || "id"]}
                >
                  {option[field.labelField || "name"]}
                </Radio>
              ))}
            </Radio.Group>
          )}
          {type === "date" && <DatePicker disabledDate={disabledDate} />}
          {type === "range" && <RangePicker disabledDate={disabledDate} />}
          {type === "switch" && <Switch />}
          {type === "TimePicker" && (
            <TimePicker use12Hours format={"hh:mm a"} />
          )}
          {type === "select" && (
            <Select
              onChange={(value) => handle_field_change(name || "", value)}
              disabled={dependsOn && !filters[field.name || ""]}
              mode={mode}
            >
              {field.options?.map((option: any) => {
                console.log(option, "field.options1");

                return (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                );
              })}
              {console.log("select_options", select_options)}
              {select_options[name || ""]?.options?.map((option: any) => {
                console.log(option, "field.options2");

                return (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                );
              })}
            </Select>
          )}
          {type === "dayRanges" && (
            <Form.List name={name} initialValue={[{ from: 1, to: 31 }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "from"]}
                        fieldKey={[name, "from"]}
                        rules={[
                          { required: true, message: "From day is required" },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const to = getFieldValue([name, "to"]);
                              if (value >= to) {
                                return Promise.reject(
                                  new Error(
                                    "From day always less than till day"
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        style={{ flex: 1 }}
                      >
                        <InputNumber
                          placeholder="From"
                          min={1}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, "to"]}
                        fieldKey={[name, "to"]}
                        rules={[
                          { required: true, message: "To day is required" },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const from = getFieldValue([name, "from"]);
                              console.log(from, value, "to_value");
                              if (value <= from) {
                                return Promise.reject(
                                  new Error(
                                    "Till day always greater than from day"
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        style={{ flex: 1 }}
                      >
                        <InputNumber
                          placeholder="To"
                          max={31}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        style={{
                          fontSize: "20px",
                          width: "30px",
                          textAlign: "center",
                        }}
                      />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add ranges between (1 to 31)
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
        </Form.Item>
      </Col>
    );
  };

  const render_action = (action: DynamicFormAction) => {
    const { label, class_name } = action;

    const handle_click = (_event: React.MouseEvent<HTMLElement>) => {
      if (action.type === "reset") {
        if (type === "add") {
          form.resetFields();
        }
      } else if (action.type === "cancel") {
        if (close_modal) {
          form.resetFields();
          close_modal(false);
        }
        if (on_action) {
          on_action(action.type);
        }
      } else if (action.type === "submit") {
        form.submit();
      } else {
        form.setFieldsValue({ ...item });
      }
    };

    if (
      (action.type === "reset" && type !== "edit") ||
      action.type === "cancel"
    ) {
      return (
        <Col key={action.type}>
          <Button
            type="default"
            className={class_name}
            onClick={handle_click}
            danger={action.type === "cancel"}
          >
            {label}
          </Button>
        </Col>
      );
    } else if (action.type === "submit") {
      return (
        <Col key={action.type}>
          <Button
            type="primary"
            className={class_name}
            loading={submit_loading}
            onClick={handle_click}
          >
            Submit
          </Button>
        </Col>
      );
    }
  };

  const handle_submit = async (values: any) => {
    let submit_action = actions.find((x) => x.type === "submit");
    if (submit_action) {
      let source =
        type === "add"
          ? submit_action.create_source
          : submit_action.update_source;
      if (source) {
        const {
          request,
          selector,
          params,
          response_path,
          response_success_value,
          response_success_message,
          response_failure_message,
        } = source;
        set_submit_loading(true);
        console.log(fields, "fields");

        fields.forEach((field: DynamicFormField) => {
          if (field.exclude) {
            delete values[field?.name];
          } else {
            if (field.type === "image") {
              if (
                values[field?.name] &&
                Array.isArray(values[field?.name]) &&
                field?.maxCount &&
                field?.maxCount > 1
              ) {
                values[field.name] = values[field.name].map(
                  (value: any) => value?.url
                );
              } else {
                values[field.name] = values[field.name]?.file?.url;
              }
            } else if (field.type === "mobile") {
              values[field.name] = String(values[field.name]);
            }
          }
        });
        let variables = { ...params };
        variables.data = { ...values, ...variables.data };
        console.log("variables", variables);

        await dispatch(request(variables)).unwrap();
        const state = store.getState();
        const data = selector(state);
        const result_error = data?.result?.error?.message?.includes(
          "Unique constraint error on field:"
        )
          ? data?.result?.error?.message
          : null;
        const status =
          response_path
            .split(".")
            .reduce((acc: any, part: any) => acc?.[part], data) || [];
        set_submit_loading(false);
        console.log(
          data,
          response_success_message,
          response_failure_message,
          "status"
        );

        switch (status) {
          case response_success_value:
            message.success(response_success_message ?? "Added successfully");
            if (close_modal) {
              form.resetFields();
              close_modal(true);
            } else {
              setTimeout(() => {
                load_item(type);
              }, 200);
            }
            break;
          default:
            message.error(
              result_error ?? response_failure_message ?? "Error in adding"
            );
            break;
        }
      }
    }
  };

  const MyForm = () => {
    return (
      <Form form={form} onFinish={handle_submit}>
        <Row gutter={16}>{fields.map(render_field)}</Row>
        <Row gutter={4} justify="end" align="middle">
          {actions.map(render_action)}
        </Row>
      </Form>
    );
  };

  if (view_type === "page") {
    return <MyForm />;
  } else {
    const handle_close = () => {
      form.resetFields();
      close_modal(false);
    };

    return (
      <CommonModal
        // closable={false}
        title={modal_title || ""}
        open={is_modal_open || false}
        footer={null}
        onCancel={handle_close}
      >
        <MyForm />
      </CommonModal>
    );
  }
};

export default DynamicForm;
