import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CheckBoxComponent from "../check_box";
import {
  AppDispatch,
  get_system_ui_module_list_request,
  update_system_ui_module_clear,
  update_system_ui_module_request,
} from "../../../../../redux";
import { update_system_ui_module } from "@src/redux/slices/system_ui_module/update_system_ui_module";

// Define the prop types
interface PageComponentRoleCheckBoxProps {
  page_component: any;
  role: { id?: string };
  key: number;
}

const PageComponentRoleCheckBox: React.FC<PageComponentRoleCheckBoxProps> = (
  props
) => {
  const { page_component, role, key } = props;
  const dispatch = useDispatch<AppDispatch>();

  const [has_access, setHasAccess] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<{ [key: string]: boolean }>(
    {}
  );

  const checkboxKey = `${page_component.id}_${role.id}`;
  const { result } = useSelector(update_system_ui_module);

  useEffect(() => {
    if (page_component) {
      const page_roles = page_component?.role_list?.map((x: any) => x?.id);
      setHasAccess(page_roles?.indexOf(role.id) > -1);
    }
  }, [page_component, role.id]);

  useEffect(() => {
    if (result?.status === "success") {
      setHasAccess(!has_access);
      setLoadingState((prevState) => ({
        ...prevState,
        [checkboxKey]: false,
      }));
      dispatch(update_system_ui_module_clear());
      dispatch(get_system_ui_module_list_request({}));
    }
  }, [result, page_component.id, role.id, has_access, dispatch]);

  const on_change_page_access = (value: boolean) => {
    setLoadingState((prevState) => ({
      ...prevState,
      [checkboxKey]: true,
    }));

    if (value) {
      dispatch(
        update_system_ui_module_request({
          id: page_component.id,
          data: {
            role_id_list: [role?.id || ""],
          },
        })
      );
    } else {
      dispatch(
        update_system_ui_module_request({
          id: page_component.id,
          data: {
            // disconnectable_role_id_list: [role.id || ""],
            removable_role_id_list: [role.id || ""],
          },
        })
      );
    }
  };

  return (
    <CheckBoxComponent
      key={key}
      is_checked={has_access}
      loading={loadingState[checkboxKey] || false}
      onChange={on_change_page_access}
    />
  );
};

export default PageComponentRoleCheckBox;
