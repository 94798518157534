import { Button, Table, Input } from "antd";
import React, { useEffect, useState } from "react";
import PageTitle from "@src/views/components/common/page_title";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  Category,
  get_category_list,
  get_category_list_request,
  get_product_stock_list,
  get_product_stock_list_request,
  update_product_stock,
  update_product_stock_clear,
  update_product_stock_request,
} from "@src/redux";
import CategoryLabel from "../components/stock_management/category_label";
import Product from "../components/stock_management/product";
import ProductInputBox from "../components/stock_management/product_input_box";
import toast from "react-hot-toast";
import { useCheckPageComponentAccess } from "@src/hooks";
interface ProductTotals {
  [id: string]: number;
}
const StockManagement: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [product_stock, set_product_stock] = useState<any[]>([]);
  const [category_list, set_category_list] = useState<any[]>([]);
  const [stock_value, set_stock_value] = useState<any>(null);
  const [search_val, set_search_val] = useState("");

  const { result: categories, loading: getCategoryLoading } =
    useSelector(get_category_list);

  const { result: get_stock_list, loading: get_stock_loading } = useSelector(
    get_product_stock_list
  );
  const {
    result: update_stock_result,
    loading: update_stock_loading,
    error: update_stock_error,
  } = useSelector(update_product_stock);
  useEffect(() => {
    dispatch(
      get_category_list_request({
        sort: [{ field: "name", order: "asc" }],
        filter: { search_string: search_val },
      })
    );
    dispatch(get_product_stock_list_request({}));
  }, [search_val]);

  useEffect(() => {
    if (update_stock_result?.status === "success") {
      dispatch(update_product_stock_clear());
      dispatch(
        get_category_list_request({ sort: [{ field: "name", order: "asc" }] })
      );
      dispatch(get_product_stock_list_request({}));
    } else if (update_stock_error) {
      toast.error(update_stock_error?.message || "");
    }
  }, [update_stock_result, update_stock_error]);

  useEffect(() => {
    if (categories?.items && categories?.items?.length > 0) {
      let category = categories?.items?.map((list: Category) => {
        return {
          ...list,
          children: list.product_list,
        };
      });
      set_category_list(category);
    }
  }, [categories]);

  const row_class_name = (
    record: any,
    index: number,
    categories_list: any
  ): string => {
    if ("children" in record) {
      let my_category_index = categories_list?.findIndex(
        (x: any) => x?.id === record?.id
      );

      if (my_category_index !== undefined && my_category_index % 2 === 0) {
        return "category-even-row";
      }
      return "category-odd-row";
    } else {
      let my_category = categories_list?.find(
        (x: any) => x?.id === record?.category_id
      );
      let my_category_products = my_category?.product_list;
      let my_index = my_category_products?.findIndex(
        (x: any) => x?.id === record?.id
      );

      const is_my_index_even = my_index !== undefined && my_index % 2 === 0;

      return is_my_index_even ? "product-even-row" : "product-odd-row";
    }
  };

  const handle_update_stock = (record, category_list, product_stock) => {
    const updated_product = product_stock?.[0];

    console.log(updated_product, "updated_product_stock");
    const params = {
      quantity: updated_product?.quantity,
    };
    if (updated_product?.quantity >= 0) {
      dispatch(
        update_product_stock_request({ id: updated_product?.id, data: params })
      );
    } else {
      toast.error("Negative stocks cannot be updated");
    }
  };

  const columns: any = [
    {
      title: "",
      dataIndex: "index",
      key: "index",
      fixed: "left",
      width: "5%",
    },
    {
      title: "Product",
      dataIndex: "",
      key: "category",
      fixed: "left",
      width: "20%",
      render: (record: any) => {
        return (
          <div
            className={record?.product_list?.length > 0 ? "group-header" : ""}
          >
            {record?.product_list ? (
              <CategoryLabel record={record} />
            ) : (
              <Product record={record} />
            )}
          </div>
        );
      },
    },
    {
      title: t("table:current_stocks"),
      dataIndex: "",
      key: "index",
      fixed: "left",
      width: "15%",
      render: (record: any) => {
        if (!record?.children) {
          let find_product_stock = get_stock_list?.items?.find((list: any) =>
            list.product_id?.includes(record?.id)
          );
          return Number(find_product_stock?.quantity) || "0";
        }
      },
    },
    {
      title: t("table:update_stocks"),
      dataIndex: "",
      key: "index",
      width: "15%",
      render: (record: any) => {
        if (!record?.children) {
          let find_product_stock = get_stock_list?.items?.find((list: any) =>
            list.product_id?.includes(record?.id)
          );
          [];
          return (
            <ProductInputBox
              key={record?.id}
              stock_value={stock_value}
              set_stock_value={set_stock_value}
              record={record}
              find_product_stock={find_product_stock}
              set_product_stock={set_product_stock}
              product_stock={product_stock}
            />
          );
        }
      },
    },
    ...(useCheckPageComponentAccess("stock_management_actions")
      ? [
          {
            title: t("table:action"),
            dataIndex: "",
            key: "index",
            width: "15%",
            render: (record: any) => {
              if (!record?.children)
                return (
                  <Button
                    type="primary"
                    onClick={() =>
                      handle_update_stock(record, category_list, product_stock)
                    }
                  >
                    {t("update_stock")}
                  </Button>
                );
            },
          },
        ]
      : []),
  ];
  const handle_search_change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    console.log(value, "value");
    set_search_val(value);
  };
  return (
    <>
      <div className="stock-page-title-container">
        <div className="product-page-title">
          <PageTitle title={t("stock_management")} is_color={true} />
        </div>

        <div className="add-drivers-button"></div>
      </div>
      <div className="stock-page-title-container">
        <div className="product-page-title"></div>

        <div className="add-drivers-button">
          <Input.Search
            className="search-box-container"
            placeholder={t("search")}
            allowClear={true}
            onChange={handle_search_change}
          />
        </div>
      </div>

      <div className="product-demand-table-container">
        <Table
          columns={columns}
          dataSource={category_list}
          pagination={false}
          scroll={{ x: "max-content" }}
          rowKey={"id"}
          sticky={{
            offsetHeader: 0,
          }}
          className="table-striped-rows admin-table"
          rowClassName={(record, index) =>
            row_class_name(record, index, category_list)
          }
          loading={getCategoryLoading}
        />
      </div>
    </>
  );
};

export default StockManagement;
