import React from "react";
import AccessRightList from "../components/access_rights/Page_component/access_right_list";

const AccessRights: React.FC = () => {
  return (
    <div>
      {" "}
      <AccessRightList />{" "}
    </div>
  );
};

export default AccessRights;
