import { combineReducers } from '@reduxjs/toolkit';
import get_system_api_module_list_reducer from './get_system_api_module_list';
import sync_system_api_module_list_reducer from './sync_system_api_module_list';

const system_api_module_reducer = combineReducers({
  get_system_api_module_list: get_system_api_module_list_reducer,
  sync_system_api_module_list: sync_system_api_module_list_reducer,
});

export default system_api_module_reducer;
