import { combineReducers } from '@reduxjs/toolkit';
import create_dispute_reducer from './create_dispute';
import delete_dispute_reducer from './delete_dispute';
import get_dispute_reducer from './get_dispute';
import get_dispute_list_reducer from './get_dispute_list';
import update_dispute_reducer from './update_dispute';

const dispute_reducer = combineReducers({
  create_dispute: create_dispute_reducer,
  delete_dispute: delete_dispute_reducer,
  get_dispute: get_dispute_reducer,
  get_dispute_list: get_dispute_list_reducer,
  update_dispute: update_dispute_reducer,
});

export default dispute_reducer;
