import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_order_payment_transaction(
    $filter: order_payment_transaction_filter_input
  ) {
    get_order_payment_transaction(filter: $filter) {
      id
      amount
      status
      currency
      initiated_date_time
      updated_date_time
      payment_txn_id
      response_date_time
      invoice_number
      order {
        customer {
          name
          mobile
          email
        }
        reference
        id
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.OrderPaymentTransactionState = {
  result: null,
  error: null,
  loading: false,
};

export const get_order_payment_transaction_request = createAsyncThunk<
  TYPES.OrderPaymentTransaction,
  { filter?: TYPES.OrderPaymentTransactionFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'order_payment_transaction/get_order_payment_transaction',
  async (
    params: { filter?: TYPES.OrderPaymentTransactionFilterInput },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_order_payment_transaction;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_order_payment_transaction_slice = createSlice({
  name: 'get_order_payment_transaction',
  initialState: initial_state,
  reducers: {
    get_order_payment_transaction_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_order_payment_transaction_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_order_payment_transaction_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        get_order_payment_transaction_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: 'Failed to fetch',
            status_code: '500',
          };
          state.loading = false;
        },
      );
  },
});

export const { get_order_payment_transaction_clear } =
  get_order_payment_transaction_slice.actions;

export const get_order_payment_transaction = createSelector(
  (state: RootState) =>
    state.order_payment_transaction.get_order_payment_transaction.result,
  (state: RootState) =>
    state.order_payment_transaction.get_order_payment_transaction.error,
  (state: RootState) =>
    state.order_payment_transaction.get_order_payment_transaction.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_order_payment_transaction_slice.reducer;
