import { Button } from "antd";
import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import CommonModal from "../../common/modal/custom_modal";
import RoleForm from "./role_form";

interface RoleModalProps {
  modal_open: boolean;
  handle_close: () => void;
  handle_submit: (e: React.FormEvent) => void; // Updated type
  action_type: string;
  id?: string;
  action_item?: {
    name?: string;
  };
  set_expanded_row_key: (key: string | null) => void;
  link_type?: string;
}

const RoleModal: React.FC<RoleModalProps> = (props) => {
  const {
    modal_open,
    handle_close,
    handle_submit,
    action_type,
    id,
    action_item,
    set_expanded_row_key,
    link_type,
  } = props;
  const [loading, set_submit_loading] = useState<boolean>(false);

  return (
    <CommonModal
      title={action_type === "add_role" ? "Create Role" : "Update Role"}
      open={modal_open}
      onCancel={handle_close}
      footer={
        <>
          <Button onClick={handle_close}>Cancel</Button>
          <Button
            key="confirm"
            type="primary"
            htmlType="submit"
            form="page_form"
            onClick={handle_submit}
            loading={loading}
          >
            {action_type === "add_role" ? "Create" : "Update"}
          </Button>
        </>
      }
    >
      <RoleForm
        close={handle_close}
        action_type={action_type}
        get_id={id}
        action_item={action_item}
        set_loading={set_submit_loading}
        set_expanded_row_key={set_expanded_row_key}
        link_type={link_type}
      />
    </CommonModal>
  );
};

export default RoleModal;
