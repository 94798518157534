import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Table,
  Button,
  Form,
  Select,
  Image,
} from "antd";
import LabelValuePair from "../common/label_value_pair";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../common/bread_crumb";
import { useNavigate, useParams } from "react-router-dom";
import { formatStatus } from "@src/helpers/status_constants";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  AppDispatch,
  get_dispute,
  get_dispute_line_item_list,
  get_dispute_line_item_list_request,
  get_dispute_request,
  update_dispute,
  update_dispute_request,
} from "@src/redux";
import CommonModal from "../common/modal/custom_modal";
import { indian_currency_format } from "@src/helpers/constants";
import moment from "moment";
import OrderLineItemForm from "../customer_order/order_line_item/order_line_item_form";
import UpdateReturnModal from "./update_modal/update_return_modal";
import { useCheckPageComponentAccess } from "@src/hooks";
import { DEFAULT_IMAGE } from "@src/helpers/images_constants";

const { Text } = Typography;
interface StatusOption {
  label: string;
  value: string;
}

const CustomerReturnDetail: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const [dispute_status, set_dispute_status] = useState<any>("");
  const [update_modal, set_update_modal] = useState<boolean>(false);

  const { result: get_dispute_details } = useSelector(get_dispute);
  const { result: get_dispute_line_item_details } = useSelector(
    get_dispute_line_item_list
  );
  const { result: update_dispute_result, loading: update_dispute_loading } =
    useSelector(update_dispute);

  const handleBack = () => {
    navigate("/admin/return-orders");
  };
  console.log(dispute_status, "dispute_status");

  useEffect(() => {
    if (get_dispute_details) {
      set_dispute_status(get_dispute_details?.status);
    }
  }, [dispatch, get_dispute_details]);
  useEffect(() => {
    dispatch(get_dispute_request({ filter: { id: id } }));
  }, [dispatch, id]);

  useEffect(() => {
    if (get_dispute_details?.customer_id && get_dispute_details?.id) {
      dispatch(
        get_dispute_line_item_list_request({
          filter: {
            dispute_id: get_dispute_details?.id,
          },
        })
      );
    }
  }, [dispatch, get_dispute_details]);

  const handle_update_dispute = (status: string) => {
    set_dispute_status(status);
    set_update_modal(true);
  };
  const handle_modal_close = () => {
    set_update_modal(false);
  };

  const columns: any = [
    {
      title: "Product",
      dataIndex: "",
      key: "product",
      render: (record: any) => {
        return (
          <div className="category-container">
            <img
              src={
                record?.order_line_item?.product?.image_list?.[0] ||
                DEFAULT_IMAGE
              }
              className="product-image"
              alt={record?.order_line_item?.name}
            />
            <h4 className="category-name">
              {record?.order_line_item?.product?.name}
            </h4>
          </div>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "",
      key: "variant",
      render: (record: any) => <Text>{record?.quantity}</Text>,
    },
    {
      title: "Price",
      dataIndex: "",
      key: "price",
      align: "right" as const,
      render: (record: any) => (
        <Text>{indian_currency_format(record?.order_line_item?.price)}</Text>
      ),
    },
    {
      title: "Total",
      key: "price",
      align: "center" as const,
      render: (record: any) => {
        console.log(record?.order_line_item?.price, record?.quantity, "record");

        return (
          <Text>
            {indian_currency_format(
              record?.order_line_item?.price * record?.quantity
            )}
          </Text>
        );
      },
    },
  ];

  const items = [
    {
      title: t("customer_return"),
      path: "/admin/return-orders",
    },
    {
      title: get_dispute_details?.reference || "",
    },
  ];

  const Status_options: StatusOption[] = [
    {
      label: "Submitted",
      value: "submitted",
    },
    {
      label: "Accepted",
      value: "accepted",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  return (
    <div style={{ padding: "24px" }}>
      <Row justify="space-between" gutter={10} style={{ marginTop: "20px" }}>
        <BreadCrumb items={items} />

        {/* <Row justify={"end"} gutter={16}>
          <Col>
            <Button type="primary" onClick={handle_update_dispute}>
              {t("update")}
            </Button>
          </Col>
          <Col>
            <Button danger onClick={handleBack}>
              {t("back")}
            </Button>
          </Col>
        </Row> */}
      </Row>
      <Row gutter={16} className="customer-order-detail-container">
        <Col span={14}>
          <Card bordered={false} className="order-card">
            <Row justify={"space-between"}>
              <Col>
                <u>
                  <h3>{t("product_details")}</h3>
                </u>
              </Col>
              <Col>
                {/* <Button type="primary" onClick={handle_add}>
                  {t("add_product")}
                </Button> */}
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={get_dispute_line_item_details?.items || []}
              pagination={false}
              className="admin-table"
              style={{ marginTop: "20px" }}
              summary={(record: any) => {
                const total = record?.reduce(
                  (sum: number, item: any) =>
                    sum +
                    Number(item?.order_line_item?.price || 0) *
                      Number(item?.order_line_item?.quantity || 0),
                  0
                );
                console.log(record, "record");

                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell align="right" colSpan={4} index={0}>
                      <Text strong>Total Value(include gst): </Text>
                      <Text strong>
                        {indian_currency_format(
                          get_dispute_details?.total_value
                        )}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                );
              }}
            />
          </Card>
        </Col>
        <Col span={10}>
          <Card bordered={false}>
            <LabelValuePair
              label="Order Id"
              value={get_dispute_details?.order?.reference || "-"}
            />
            <LabelValuePair
              label="Name"
              value={get_dispute_details?.customer?.name || "-"}
            />
            <LabelValuePair
              label="Email"
              value={get_dispute_details?.customer?.email || "-"}
            />
            <LabelValuePair
              label="Mobile No"
              value={get_dispute_details?.customer?.mobile || "-"}
            />
            <LabelValuePair
              label="Status"
              value={formatStatus(get_dispute_details?.status || "-")}
            />
            {get_dispute_details?.status === "accepted" && (
              <LabelValuePair
                label="Given Credits"
                value={get_dispute_details?.total_credits || "-"}
              />
            )}

            <Row justify={"end"} gutter={16}>
              {get_dispute_details?.status === "submitted" && (
                <>
                  <Col>
                    {useCheckPageComponentAccess("resolved_button") && (
                      <Button
                        type="primary"
                        onClick={() => handle_update_dispute("accepted")}
                      >
                        {t("accept")}
                      </Button>
                    )}
                  </Col>
                  <Col>
                    {useCheckPageComponentAccess("rejected_button") && (
                      <Button
                        danger
                        onClick={() => handle_update_dispute("rejected")}
                      >
                        {t("reject")}
                      </Button>
                    )}
                  </Col>
                </>
              )}
            </Row>
            <br />

            <u>
              <h3>{t("franchise_details")}</h3>
            </u>
            <br />

            <LabelValuePair
              label="Name"
              value={get_dispute_details?.franchise?.name || "-"}
            />
            <LabelValuePair
              label="Address"
              value={get_dispute_details?.franchise?.address || "-"}
            />
            <LabelValuePair
              label="Mobile No"
              value={get_dispute_details?.franchise?.mobile || "-"}
            />
          </Card>
        </Col>
      </Row>
      {/* <Row justify="start" gutter={10} style={{ marginTop: "20px" }}> */}
      {/* <Col>
          <Button type="primary" onClick={handle_update_dispute}>
            {t("update")}
          </Button>
        </Col>
        <Col>
          <Button danger onClick={handleBack}>
            {t("back")}
          </Button>
        </Col> */}
      <Row gutter={16} className="customer-order-detail-container">
        <Col span={14}>
          <Card bordered={false}>
            <div className="return-remark-label">{t("customer_remarks")}</div>
            <Row>
              <Col span={12}>
                <Image width={200} src={get_dispute_details?.image_list?.[0]} />
              </Col>
              <Col span={12}>
                <Text>{get_dispute_details?.remark}</Text>
              </Col>
            </Row>
          </Card>
        </Col>
        {get_dispute_details?.customer_remark && (
          <Col span={10}>
            <Card bordered={false}>
              <div className="return-remark-label">{t("reason")}</div>
              <Row>
                <Col span={24}>
                  <Text>
                    {get_dispute_details?.customer_remark || "No Remark added"}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
      </Row>
      {/* </Row> */}
      {/* update Modal */}
      <CommonModal
        title={"Update Return Order"}
        open={update_modal}
        onCancel={handle_modal_close}
      >
        <UpdateReturnModal
          form={form}
          //   line_item={order_Line_item}
          onClose={handle_modal_close}
          dispute_status={dispute_status}
        />
      </CommonModal>
    </div>
  );
};

export default CustomerReturnDetail;
