import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_order_list(
    $filter: order_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_order_list(filter: $filter, pagination: $pagination, sort: $sort) {
      items {
        customer {
          id
          name
        }
        franchise {
          id
          name
          address
          mobile
          email
        }
        line_item_list {
          id
          order_id
          product_id
          quantity
          price
        }
        ordered_datetime
        status
        reference
        total_amount
        payment_status
        total_gst
        brand_fee
        franchise_id
        customer_id
        id
      }
      pagination {
        total_count
        page_limit
        page_number
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.OrderListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_order_list_request = createAsyncThunk<
  TYPES.OrderList,
  {
    filter?: TYPES.OrderFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "order/get_order_list",
  async (
    params: {
      filter?: TYPES.OrderFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_order_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_order_list_slice = createSlice({
  name: "get_order_list",
  initialState: initial_state,
  reducers: {
    get_order_list_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_order_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_order_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_order_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_order_list_clear } = get_order_list_slice.actions;

export const get_order_list = createSelector(
  (state: RootState) => state.order.get_order_list.result,
  (state: RootState) => state.order.get_order_list.error,
  (state: RootState) => state.order.get_order_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_order_list_slice.reducer;
