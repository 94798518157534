import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import { Table, Modal, message } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useDispatch } from "react-redux";
import store from "@src/redux/store";
import lodash, { result } from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FilterBox from "./filter_box";
import { DynamicFilterField } from "../dynamic_form";
import { useLocation } from "react-router-dom";
import { useCheckPageComponentAccess } from "@src/hooks";

const { confirm } = Modal;

export interface DynamicTableColumn {
  title: string;
  data_index?: string;
  fixed?: "left" | "right";
  sorter?: true | false;
  actions?: DynamicTableActionColumn[];
  render?: (text: any, record: any, index: number) => React.ReactNode;
  action_access_key?: string;
  sorted_by?: string;
}

export interface DynamicTableActionColumn {
  type: string;
  icon?: React.ReactNode;
  title?: string;
  title_replace_field?: string;
  content?: string;
  ok_text?: string;
  cancel_text?: string;
  access_key?: string;
  source?: {
    selector: Function;
    request: Function;
    filter_key: string;
    filter_value_field: string;
    response_path: string;
    response_success_value: string;
    response_success_message: string;
    response_failure_message: string;
  };
}

export interface DynamicTableHandle {
  load_data: () => void;
}

interface DynamicTableProps {
  ref: any;
  columns: ColumnsType<DynamicTableActionColumn>;
  filters?: DynamicFilterField[];
  source: {
    request: Function;
    selector: Function;
    path?: string | "result.items";
    params: {
      filter?: any | {};
      pagination?: any | null;
      sort?: any | [];
    };
  };
  pagination?:
    | false
    | { page_size: number; show_total: string | boolean; page_number?: number };
  on_action: (type: string, record: any) => void;
}

const DynamicTable: React.FC<DynamicTableProps> = forwardRef(
  ({ columns, filters, source, pagination = undefined, on_action }, ref) => {
    useImperativeHandle(ref, () => ({
      load_data,
    }));
    const location = useLocation();

    const extended_columns = columns
      .filter((col: any, index: number) => {
        if (col?.action_access_key) {
          if (useCheckPageComponentAccess(col?.action_access_key)) {
            return col;
          } else return null;
        } else {
          return col;
        }
      })
      .map((col: any, index: number) => {
        if (col.actions) {
          return {
            ...col,
            render: (text: any, record: any) => (
              <span>
                {col.actions.map((action: any) => (
                  <>
                    {useCheckPageComponentAccess(action.access_key) && (
                      <a
                        key={action.type}
                        onClick={() => handle_action(action, record)}
                        style={{ marginRight: 8, color: "black" }}
                      >
                        {action.icon}
                      </a>
                    )}
                  </>
                ))}
              </span>
            ),
          };
        }
        return {
          ...col,
          dataIndex: col.data_index,
        };
      });

    const dispatch = useDispatch();
    const [raw_data_source, set_raw_data_source] = useState([]);
    const [params, set_params] = useState<null | any>(null);
    const [page_number, set_page_number] = useState<number>(1);
    const [loading, set_loading] = useState<boolean>(false);
    const [page_size, set_page_size] = useState<number>(10);
    const [total_count, set_total_count] = useState<number | null>(null);
    const load_data = async () => {
      const { request, selector, path } = source;
      await dispatch(request(params)).unwrap();

      const state = store.getState();
      const data = selector(state);
      let source_path = path || "result.items";
      const items =
        source_path.split(".").reduce((acc: any, part) => acc?.[part], data) ||
        [];
      set_loading(false);
      try {
        let pagination_path = "result.pagination";
        const pagination_result =
          pagination_path
            .split(".")
            .reduce((acc: any, part) => acc?.[part], data) || [];
        if (items.length === 0 && pagination_result.page_number > 1) {
          const total_pages = Math.ceil(
            pagination_result?.total_count / pagination_result?.page_limit
          );
          let largest_page_number = Math.min(page_number, total_pages);
          if (largest_page_number === 0) {
            largest_page_number = 1;
          }
          let _params = { ...params };
          if (_params.pagination) {
            _params.pagination.page_number = largest_page_number;
            setTimeout(() => {
              set_page_number(largest_page_number);
            }, 200);
          }
          set_params(_params);
          return;
        }
        set_total_count(pagination_result.total_count);
      } catch (e) {}
      set_raw_data_source(items);
    };

    useEffect(() => {
      if (!params) {
        let _params = {
          ...source.params,
        };
        if (pagination) {
          set_page_size(pagination.page_size);
          _params.pagination = {
            page_number: 1,
            page_limit: pagination.page_size,
          };
        }
        set_params(_params);
      }
    }, [source.params, location]);

    useEffect(() => {
      if (params) {
        set_loading(true);
        load_data();
      }
    }, [params]);

    const data_source = raw_data_source.map((item: any) =>
      extended_columns.reduce(
        (acc: any, column: any) => {
          if (
            typeof column.data_index === "string" &&
            column.data_index.includes(".")
          ) {
            acc[column.data_index] = lodash.get(item, column.data_index);
          }
          return acc;
        },
        { ...item }
      )
    );

    const handle_change: TableProps<any>["onChange"] = (
      pagination,
      filters,
      sorter: any
    ) => {
      let _params = { ...params };
      if (sorter?.order) {
        console.log(sorter, "sorter");

        let sorter_params = {};
        if (sorter?.column?.sorted_by?.includes(".")) {
          let sort_array = sorter?.column?.sorted_by.split(".");

          console.log(sort_array, "sort_array");

          sorter_params = sort_array.reduceRight((acc, key, index) => {
            if (index === sort_array.length - 1) {
              return { field: key, order: sorter.order.replace("end", "") };
            } else {
              return { [key]: acc };
            }
          }, {});
        } else {
          sorter_params = {
            field: sorter?.column?.sorted_by
              ? sorter?.column?.sorted_by
              : sorter.field,
            order: sorter.order.replace("end", ""),
          };
        }
        console.log(sorter_params, "sorter_params");

        _params.sort = [sorter_params];
      } else {
        _params.sort = [];
      }
      if (pagination) {
        _params.pagination = {
          page_number: pagination.current,
          page_limit: pagination.pageSize,
        };
        set_page_size(pagination.pageSize || 10);
        set_page_number(pagination.current || 1);
      }
      set_params(_params);
    };

    const handle_action = (action: any, record: any) => {
      if (!action.source) {
        on_action(action.type, record);
      } else {
        const {
          request,
          selector,
          filter_key,
          filter_value_field,
          response_path,
          response_success_value,
          response_success_message,
          response_failure_message,
        } = action.source;
        if (action.type === "delete") {
          confirm({
            title: action.title
              ? action.title.replace(
                  "{item_name}",
                  lodash.get(record, action.title_replace_field ?? "name")
                )
              : "Are you sure you want to delete this item?",
            icon: <ExclamationCircleOutlined />,
            content: action.content ?? `This action cannot be undone.`,
            okType: "danger",
            okText: action.ok_text ?? "Yes",
            cancelText: action.cancel_text ?? "No",
            style: { padding: "10px" },
            async onOk() {
              let variables = {
                [filter_key]: lodash.get(record, filter_value_field),
              };
              await dispatch(request(variables)).unwrap();
              const state = store.getState();
              const data = selector(state);
              const status =
                response_path
                  .split(".")
                  .reduce((acc: any, part: any) => acc?.[part], data) || [];
              switch (status) {
                case response_success_value:
                  message.success(
                    response_success_message ?? "Deleted successfully"
                  );
                  load_data();
                  break;
                default:
                  message.error(
                    response_failure_message ?? "Error in deleting"
                  );
                  break;
              }
            },
            onCancel() {},
          });
        }
      }
    };

    return (
      <>
        {filters && filters.length > 0 && (
          <FilterBox
            filters={filters}
            params={params}
            set_params={set_params}
          />
        )}
        <Table
          dataSource={data_source}
          columns={extended_columns}
          rowKey={"id"}
          loading={loading}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "row-even" : "row-odd"
          }
          pagination={
            pagination
              ? {
                  current: page_number,
                  pageSize: page_size,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30", "50", "100"],
                  total: total_count || 0,
                  showTotal: pagination.show_total
                    ? (total: any) =>
                        typeof pagination.show_total === "string"
                          ? pagination.show_total.replace(
                              "{total}",
                              total.toString()
                            )
                          : `Total ${total} items`
                    : undefined,
                }
              : false
          }
          scroll={{ x: "max-content" }}
          onChange={handle_change}
          className="admin-table"
        />
      </>
    );
  }
);

export default DynamicTable;
