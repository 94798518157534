import { combineReducers } from '@reduxjs/toolkit';
import get_order_payment_transaction_reducer from './get_order_payment_transaction';
import get_order_payment_transaction_list_reducer from './get_order_payment_transaction_list';

const order_payment_transaction_reducer = combineReducers({
  get_order_payment_transaction: get_order_payment_transaction_reducer,
  get_order_payment_transaction_list:
    get_order_payment_transaction_list_reducer,
});

export default order_payment_transaction_reducer;
