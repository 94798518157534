import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Flex, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  create_forgot_password_log,
  create_forgot_password_log_clear,
  create_forgot_password_log_request,
  verify_otp,
  verify_otp_clear,
  verify_otp_request,
} from "@src/redux";
import { VERTACE_LOGO } from "@src/helpers/images_constants";
import toast from "react-hot-toast";
import { IoMdArrowRoundBack } from "react-icons/io";

const ForgetPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [username, set_username] = useState("");
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);

  // State to track which form is visible
  const [currentStep, setCurrentStep] = useState<"email" | "otp" | "password">(
    "email"
  );

  const [otpTimer, setOtpTimer] = useState(180); // 180 seconds = 3 minutes
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const {
    result: forget_password_result,
    loading: forget_password_loading,
    error: forget_password_error,
  } = useSelector(create_forgot_password_log);

  const {
    result: verify_otp_result,
    loading: verify_otp_loading,
    error: verify_otp_error,
  } = useSelector(verify_otp);

  useEffect(() => {
    if (forget_password_result?.status === "success") {
      setCurrentStep("otp");
      toast.success("OTP sent successfully");
      setOtpTimer(180);
      dispatch(create_forgot_password_log_clear());
    } else if (forget_password_result?.error?.message) {
      dispatch(create_forgot_password_log_clear());
      toast.error(forget_password_result?.error?.message);
    } else if (forget_password_error?.message) {
      dispatch(create_forgot_password_log_clear());
      toast.error("Failed to reset password");
    }
  }, [forget_password_result, forget_password_error]);
  console.log(forget_password_result, "forget_password_result");

  useEffect(() => {
    if (verify_otp_result?.status === "success") {
      toast.success("Password changed successfully");
      navigate("/admin/login");
      dispatch(verify_otp_clear());
    } else if (verify_otp_result?.error?.message) {
      toast.error(verify_otp_result?.error?.message);
      dispatch(verify_otp_clear());
    } else if (verify_otp_error?.message) {
      toast.error("Failed to reset password");
      dispatch(verify_otp_clear());
    }
  }, [verify_otp_result, verify_otp_error]);
  useEffect(() => {
    if (otpTimer > 0 && currentStep === "otp") {
      const timerInterval = setInterval(() => {
        setOtpTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timerInterval);
    } else if (otpTimer === 0) {
      setIsResendDisabled(false); // Enable resend button after timer ends
    }
  }, [otpTimer, currentStep]);

  const handleEmailSubmit = (values: { username: string }) => {
    set_username(values?.username);
    dispatch(
      create_forgot_password_log_request({
        data: { username: values?.username },
      })
    );
  };

  const handleOtpSubmit = (values: {
    otp: string;
    password: string;
    confirm_password: string;
  }) => {
    const otp = otpDigits.join("");
    console.log("OTP Form Values:", values);
    dispatch(
      verify_otp_request({
        data: {
          username: username,
          otp: otp,
          new_password: values?.password,
          confirm_password: values?.confirm_password,
        },
      })
    );
  };

  const handleOtpChange = (value: string, index: number) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = value;

    if (value && index < otpDigits.length - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }

    setOtpDigits(newOtpDigits);
  };
  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otpDigits[index] && index > 0) {
      const previousInput = document.getElementById(`otp-input-${index - 1}`);
      if (previousInput) {
        previousInput.focus();
      }
    }
  };
  const handleResendOtp = () => {
    setIsResendDisabled(true);
    setOtpTimer(180); // Reset timer to 3 minutes
    dispatch(create_forgot_password_log_request({ data: { username } }));
  };

  const handleRedirect = () => {
    window.open("https://vertace.com/");
  };
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="login-page-main-container">
      <div className="forget-password">
        <div className="back-button">
          <Button type="text" onClick={handleBack}>
            <IoMdArrowRoundBack size={23} />
          </Button>
        </div>
        <h2>{t("forget_password")}</h2>

        {/* Email Form */}

        <Form
          name="emailForm"
          className="login-form"
          layout="vertical"
          autoComplete="off"
          onFinish={handleEmailSubmit}
        >
          <Form.Item
            label="Email"
            name="username"
            rules={[
              { required: true, message: "Please enter Email" },
              {
                type: "email",
                message: "Please enter valid Email",
              },
            ]}
          >
            <Input disabled={currentStep !== "email"} />
          </Form.Item>
          {currentStep === "email" && (
            <Row align="stretch" justify="center">
              <Col span={8} className="login-buttons-forget-password">
                <Button
                  type="primary"
                  className="admin-login-button"
                  onClick={handleBack}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={8} className="login-buttons-forget-password">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="admin-login-button"
                  loading={forget_password_loading}
                >
                  Submit Email
                </Button>
              </Col>
            </Row>
          )}
        </Form>

        {/* OTP Form */}

        {currentStep !== "email" && (
          <Form
            name="otpForm"
            className="login-form"
            layout="vertical"
            autoComplete="off"
            onFinish={handleOtpSubmit}
          >
            <Form.Item
              label="OTP"
              required
              name="otp"
              rules={[
                { required: true, message: t("error:please_enter_otp") },
                {
                  pattern: /^[0-9\s]+$/,
                  message: t("error:only_numbers_were_allowed"),
                },
              ]}
            >
              <Space
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {otpDigits.map((digit, index) => (
                  <Input
                    id={`otp-input-${index}`}
                    key={index}
                    value={digit}
                    maxLength={1}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                    onKeyDown={(e) => handleOtpKeyDown(e, index)} // Handle deletion
                    style={{ width: 50, height: 50, textAlign: "center" }}
                  />
                ))}
              </Space>
            </Form.Item>
            {/* <Form.Item
              label="OTP"
              name="otp"
              rules={[
                {
                  required: true,
                  message: t("error:please_enter_the_otp"),
                },
              ]}
            >
              <Flex gap="middle" align="center" vertical justify="space-around">
                <Input.OTP
                  mask={"*"}
                  disabled={currentStep !== "otp"}
                  length={4}
                  className="custom-otp-input"
                />
              </Flex>
            </Form.Item> */}

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: t("error:please_enter_password") },
                {
                  pattern: /^.{6,12}$/,
                  message: "Password must be between 6 and 12 characters.",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirm_password"
              dependencies={["password"]}
              rules={[
                { required: true, message: t("error:please_confirm_password") },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("error:passwords_do_not_match"))
                    );
                  },
                }),
                {
                  pattern: /^.{6,12}$/,
                  message: "Password must be between 6 and 12 characters.",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            {currentStep === "otp" && (
              <Row align="middle" justify="center">
                <Col span={8} className="login-buttons-forget-password">
                  <Button
                    type="primary"
                    onClick={handleResendOtp}
                    disabled={isResendDisabled}
                  >
                    Resend OTP {isResendDisabled ? `(${otpTimer}s)` : ""}
                  </Button>
                </Col>
                <Col span={8} className="login-buttons-forget-password">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="admin-login-button"
                    loading={verify_otp_loading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        )}

        <div className="login-footer-container">
          <span>{t("powered_by")}</span>
          <img
            src={VERTACE_LOGO}
            className="vertace-logo"
            onClick={handleRedirect}
            alt="Vertace"
          />
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
