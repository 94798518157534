import { Button, Typography } from "antd";
import React, { useState } from "react";
import PageModal from "./page_modal";
import PageList from "./page_list";
import SyncEndPoints from "../end_points/sync_end_points";
import EndPointsModal from "../sync_end_points/end_points_modal";
import { useTranslation } from "react-i18next";
import { IoMdAddCircleOutline } from "react-icons/io";
import RoleModal from "./role_modal";
import PageTitle from "../../../components/common/page_title";

// Define types for the component's state
interface ActionItem {
  [key: string]: any; // Adjust this to match the shape of your action items
}

const AccessRightList: React.FC = () => {
  const { t } = useTranslation();

  const [modal_open, set_open_modal] = useState<boolean>(false);
  const [open_role_modal, set_open_role_modal] = useState<boolean>(false);
  const [link_modal_open, set_link_open_modal] = useState<boolean>(false);

  const [get_id, set_filed_id] = useState<string>("");
  const [action_type, set_action_type] = useState<string | null>(null);
  const [link_type, set_link_type] = useState<string | null>(null);

  const [action_item, set_action_item] = useState<ActionItem>({});
  const [expanded_row_key, set_expanded_row_key] = useState<string | null>(
    null
  );

  const handle_add = () => {
    set_open_modal(true);
    set_action_type("add_page");
    set_action_item({});
  };

  const handle_add_role = () => {
    set_open_role_modal(true);
    set_action_type("add_role");
    set_action_item({});
  };

  const handle_close = () => {
    set_open_modal(false);
    set_action_type("");
    set_open_role_modal(false);
  };

  const handle_link_close = () => {
    set_link_open_modal(false);
    set_action_type("");
  };

  const handle_submit = (e: React.FormEvent) => {
    // Your submit logic here
  };

  return (
    <div style={{ width: "100%" }}>
      <PageTitle title={t("access_rights")} is_color={true} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px 0px",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <Button
          icon={<IoMdAddCircleOutline color="white" />}
          onClick={handle_add_role}
          className="add-button"
        >
          {t("role")}
        </Button>
        <Button
          icon={<IoMdAddCircleOutline color="white" />}
          onClick={handle_add}
          className="add-button"
        >
          {t("add_page")}
        </Button>
        {/* <SyncEndPoints /> */}
      </div>

      <PageModal
        modal_open={modal_open}
        handle_close={handle_close}
        handle_submit={handle_submit}
        action_type={action_type || ""}
        id={get_id}
        action_item={action_item}
        link_type={link_type || ""}
        set_expanded_row_key={set_expanded_row_key}
      />
      <RoleModal
        modal_open={open_role_modal}
        handle_close={handle_close}
        handle_submit={handle_submit}
        action_type={action_type || ""}
        id={get_id}
        action_item={action_item}
        link_type={link_type || ""}
        set_expanded_row_key={set_expanded_row_key}
      />
      <EndPointsModal
        action_type={action_type || ""}
        link_type={link_type || ""}
        link_modal_open={link_modal_open}
        handle_link_close={handle_link_close}
        id={get_id || ""}
        handle_submit={handle_submit}
        action_item={action_item}
      />
      <PageList
        set_open_modal={set_open_modal}
        set_action_type={set_action_type}
        set_filed_id={set_filed_id}
        set_action_item={set_action_item}
        set_link_open_modal={set_link_open_modal}
        get_id={get_id}
        set_expanded_row_key={set_expanded_row_key}
        expanded_row_key={expanded_row_key}
        set_link_type={set_link_type}
      />
    </div>
  );
};

export default AccessRightList;
